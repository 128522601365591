import * as React from "react";

const BlogTitle = ({ record }) => {
  return (
    <span>
      <span>{`Blog_title - ${record.title}`}</span>
    </span>
  );
};

export default BlogTitle;
