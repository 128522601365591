import React from "react";
import { TextField, FormLabel } from "@material-ui/core";

import CvInput from "./CvInput";

const OtherDataForm = ({ register, errors, watch }) => {
  return (
    <>
      <CvInput {...{ register, watch, errors }} />
      <div className="other-data">
        <FormLabel component="legend">Այլ տեղեկություններ և նշումներ</FormLabel>
        <TextField name="otherInformation" inputRef={register} />
      </div>
    </>
  );
};

export default OtherDataForm;
