import simpleRestDataProvider from "./simpleRestDataProvider"

export default function updateCourses(resource, params, file) {
    const { data } = params;

    if (data.languages) {
        const logos = data.languages.filter((e) => e.logo.rawFile instanceof File);

        if (logos.length === 0 && !data.picture) {
            return simpleRestDataProvider.update(resource, params);
        }

        if (logos.length !== 0) {
            logos.forEach((e) => {
                file.append("logos", e.logo.rawFile);
            });
        }
    }

    if (data.picture) file.append("file", data.picture.rawFile);

    return fetch(`${process.env.REACT_APP_BACKEND_PORT}/${resource}ImageUpload`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
        },
        body: file,
    })
        .then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then((res) => {
            const updatedData = { ...data };
            if (res.fileName) updatedData.picture = res.fileName;

            if (res.logos !== undefined) {
                res.logos.forEach((newLogo) => {
                    for (let value of data.languages) {
                        console.log(value.logo.rawFile, "value.logo.rawFile");
                        console.log(newLogo, "newLogo");
                        if (value.logo.rawFile instanceof File) {
                            value.logo = newLogo;
                            break;
                        }
                    }
                });
            }

            return simpleRestDataProvider.update(resource, {
                ...params,
                data: updatedData,
            });
        })
        .catch((error) => Promise.reject(error));
}