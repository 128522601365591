import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  ListButton,
  TopToolbar,
  EditButton,
  FunctionField,
} from "react-admin";

import SettingTitle from "./SettingTitle";

const SettingShowActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const CategoryOptionField = (record) => {
  let option = null;
  if (record.category === "setting") {
    option = (
      <div>
        <h3>Option name</h3>
        <p> {record.settingName}</p>
        <h3>Option value</h3>
        <p> {record.value}</p>
      </div>
    );
  } else if (record.category === "logo") {
    option = (
      <>
        <h3>Logo</h3>
        <img
          width={"100%"}
          height={"100%"}
          src={`${process.env.REACT_APP_BACKEND_PORT}/${record.logoPicture}`}
          alt="Logo_picture"
        />
        <h3>Icon</h3>
         <img
          width={"100%"}
          height={"100%"}
          src={`${process.env.REACT_APP_BACKEND_PORT}/${record.logoIcon}`}
          alt="Logo_icon"
        />
      </>
    );
  } else if (record.category === "banner") {
    option = (
      <>
        <h3>Banner picture</h3>
        <img
          width={"100%"}
          height={"100%"}
          src={`${process.env.REACT_APP_BACKEND_PORT}/${record.bannerPicture}`}
          alt="Banner_picture"
        />
        <h3>Banner texts</h3>
        <ul>
          {record.bannerTexts.map((e) => (
            <li key={e._id}>{e.bannerText}</li>
          ))}
        </ul>
      </>
    );
  }

  return (
    <>
      {option}
    </>
  );
};

const SettingShow = (props) => {
  return (
    <Show {...props} title={<SettingTitle />} actions={<SettingShowActions />}>
      <SimpleShowLayout>
        <FunctionField
          source="category"
          label=""
          render={(record) => CategoryOptionField(record)}
        />
      </SimpleShowLayout>
    </Show>
  );
};

export default SettingShow;
