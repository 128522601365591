import ClassIcon from "@material-ui/icons/Class";
import ScrapbookList from "./ScrapbookList";


const scrapbook = {
  list: ScrapbookList,
  icon: ClassIcon,
};

export default scrapbook;
