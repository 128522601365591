import * as React from "react";
import { Children, cloneElement, memo } from "react";
import { useMediaQuery, makeStyles } from "@material-ui/core";
import lodashGet from "lodash/get";
import jsonExport from "jsonexport/dist";
import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  downloadCSV,
  EditButton,
  List,
  ShowButton,
  SimpleList,
  TextField,
} from "react-admin";

const exporter = (FAQ) => {
  const data = FAQ.map((FAQ) => ({
    ...FAQ,
    backlinks: lodashGet(FAQ, "backlinks", []).map((backlink) => backlink.url),
  }));
  jsonExport(data, (err, csv) => downloadCSV(csv, "FAQ"));
};

const FAQListBulkActions = memo((props) => (
  <BulkDeleteWithConfirmButton {...props} />
));

const useStyles = makeStyles(() => ({
  field: {
    maxWidth: "15em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

const useFAQListActionToolbarStyles = makeStyles({
  toolbar: {
    alignItems: "center",
    display: "flex",
    marginTop: -1,
    marginBottom: -1,
  },
});

const FAQListActionToolbar = ({ children, ...props }) => {
  const classes = useFAQListActionToolbarStyles();
  return (
    <div className={classes.toolbar}>
      {Children.map(children, (button) => cloneElement(button, props))}
    </div>
  );
};

const FAQList = (props) => {
  const classes = useStyles();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <List
      {...props}
      bulkActionButtons={<FAQListBulkActions />}
      exporter={exporter}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => `Question: ${record.question}`}
          secondaryText={(record) => `Answer: ${record.answer}`}
        />
      ) : (
        <Datagrid rowClick="show" optimized>
          <TextField
            source="question"
            cellClassName={classes.field}
            sortable={false}
          />
          <TextField
            source="answer"
            cellClassName={classes.field}
            sortable={false}
          />
          <FAQListActionToolbar>
            <EditButton />
            <ShowButton />
          </FAQListActionToolbar>
        </Datagrid>
      )}
    </List>
  );
};

export default FAQList;
