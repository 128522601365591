const handleUpdateFilde = (data, previousData) => {
    const updatedData = {};
    for (let prop in previousData) {
        if (prop === "tutors") continue;
        if (Array.isArray(previousData[prop])) {
            data[prop].forEach((e, i) => {
                if (typeof e === "object") {
                    for (let p in e) {
                        if (p === "_id") continue;
                        if (previousData[prop].length < data[prop].length) {
                            updatedData[prop] = data[prop];
                        } else if (previousData[prop].length > data[prop].length) {
                            updatedData[prop] = data[prop];
                        } else if (previousData[prop][i][p] !== e[p]) {
                            updatedData[prop] = data[prop];
                        }
                    }
                } else {
                    if (e !== previousData[i]) {
                        updatedData[prop] = data[prop];
                    }
                }
            });
        } else if (data[prop] !== previousData[prop]) {
            updatedData[prop] = data[prop];
        }
    }
    return updatedData;
};


export default handleUpdateFilde