import * as React from "react";
import { Admin, Resource } from "react-admin";
import { Redirect, Route } from "react-router-dom";
import { createBrowserHistory as createHistory } from "history";

import authProvider from "./authProvider";
import dataProvider from "./dataProvider";

import Layout from "./Layout";
import FAQ from "./FAQs";
import user from "./users";
import course from "./courses";
import blog from "./blogs";
import team from "./teams";
import review from "./reviews";
import setting from "./settings";
import scrapbook from "./scrapbook";

const history = createHistory();

const CustomRoutes = [
  <Route
    exact
    path="/admin"
    render={() => <Redirect to="admin/FAQs" />}
    noLayout
  />,
];

const AdminPage = () => {
  return (
    <Admin
      title="Admin Panel"
      disableTelemetry
      customRoutes={CustomRoutes}
      authProvider={authProvider}
      dataProvider={dataProvider}
      history={history}
      layout={Layout}
    >
      <Resource name="admin/FAQs" options={{ label: "FAQs" }} {...FAQ} />
      <Resource name="admin/blogs" options={{ label: "Blogs" }} {...blog} />
      <Resource
        name="admin/courses"
        options={{ label: "Courses" }}
        {...course}
      />
      <Resource name="admin/users" options={{ label: "Users" }} {...user} />
      <Resource name="admin/teams" options={{ label: "Teams" }} {...team} />
      <Resource
        name="admin/reviews"
        options={{ label: "Reviews" }}
        {...review}
      />
      <Resource
        name="admin/settings"
        options={{ label: "Settings" }}
        {...setting}
      />
      <Resource
        name="admin/scrapbooks"
        options={{ label: "Scrapbook" }}
        {...scrapbook}
      />
    </Admin>
  );
};
export default AdminPage;
