import * as React from "react";
import { Children, cloneElement, memo } from "react";
import { useMediaQuery, makeStyles } from "@material-ui/core";
import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  EditButton,
  ShowButton,
  List,
  BooleanField,
  FunctionField,
  SimpleList,
  TextField,
  NumberField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin";

import { PreviewAvatar } from "../common/components";

const useFAQListActionToolbarStyles = makeStyles({
  toolbar: {
    alignItems: "center",
    display: "flex",
    marginTop: -1,
    marginBottom: -1,
  },
});

const CourseListActionToolbar = ({ children, ...props }) => {
  const classes = useFAQListActionToolbarStyles();
  return (
    <div className={classes.toolbar}>
      {Children.map(children, (button) => cloneElement(button, props))}
    </div>
  );
};

const CourseBulkActionButtons = memo((props) => (
  <BulkDeleteWithConfirmButton {...props} />
));

const CourseList = ({ ...props }) => {

  return <List {...props} bulkActionButtons={<CourseBulkActionButtons />}>
    {useMediaQuery((theme) => theme.breakpoints.down("sm")) ? (
      <SimpleList
        primaryText={(record) => `Title: ${record.title}`}

        leftAvatar={(record) => PreviewAvatar(record)}
      />
    ) : (
        <Datagrid rowClick="show" optimized>
          <FunctionField
            label="Avatar"
            render={(record) => PreviewAvatar(record)}
          />
          <TextField source="title" sortable={false} />
          <NumberField
            source="availableIndividualPrice"
            label="Available individual price"
          />
          <NumberField
            source="availableGroupPrice"
            label="Available group price"
          />
          <NumberField
            source="onlineIndividualPrice"
            label="Online individual price"
          />
          <NumberField source="onlineGroupPrice" label="Online group price" />
          <NumberField source="studentsQuantity" label="Students quantity" />
          <NumberField source="duration" sortable={false} />
          <BooleanField label="Available" source="available" />
          <BooleanField label="Display on slides" source="displayՕnSlides" />
          <ReferenceArrayField
            label="tutors"
            source="tutors"
            reference="admin/users"
            sortable={false}
          >
            <SingleFieldList>
              <ChipField source="fullName" size="small" />
            </SingleFieldList>
          </ReferenceArrayField>
          <ReferenceArrayField
            label="Information about tutor"
            source="tutorsInfo"
            reference="admin/teams"
            sortable={false}
          >
            <SingleFieldList>
              <ChipField source="fullName" size="small" />
            </SingleFieldList>
          </ReferenceArrayField>
          <CourseListActionToolbar>
            <EditButton />
            <ShowButton />
          </CourseListActionToolbar>
        </Datagrid>
      )}
  </List>
};

export default CourseList;
