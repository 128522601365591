import React from 'react'
import PropTypes from 'prop-types'
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {
    useGlobalFilter,
    usePagination,
    useRowSelect,
    useSortBy,
    useTable,
} from 'react-table'

import EditableCell from "./EditableCell"
import ExtraLessonEditableCell from "./ExtraLessonEditableCell"


const defaultColumn = {
    Cell: EditableCell,
}

const ScrapbookTable = ({
    columns,
    data,
    updateMyData,
}) => {
    const {
        getTableProps,
        headerGroups,
        prepareRow,
        page,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            updateMyData,
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
    )
    return (
      <>
        <ExtraLessonEditableCell {...{ data, updateMyData }} />
        <TableContainer >
            <MaUTable {...getTableProps()} size='small' style={{ marginBottom: "25px" }} >
                <TableHead>
                    {headerGroups.map(headerGroup => (
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <TableCell
                                    {...column.getHeaderProps()}
                                >
                                    {column.render('Header')}

                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody>
                    {page.map((row) => {
                        prepareRow(row)
                        return (
                            <TableRow {...row.getRowProps()} >
                                {row.cells.map(cell => {
                                    return (
                                        <TableCell
                                            style={{
                                                    backgroundColor: cell.row.original.extraԼesson === cell.value && cell.value !== "" ? "red" : cell.value !== ""
                                                        && typeof cell.value !== "number"
                                                        ? "#D3D3D3" : ""
                                                }}
                                           {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </MaUTable>
        </TableContainer>
       </>
    )
}

ScrapbookTable.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    updateMyData: PropTypes.func.isRequired
}



export default ScrapbookTable
