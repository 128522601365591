import { AssignmentRounded } from "@material-ui/icons";
import BlogCreate from "./BlogCreate";
import BlogEdit from "./BlogEdit";
import BlogList from "./BlogList";
import BlogShow from "./BlogShow";

const blog = {
  list: BlogList,
  create: BlogCreate,
  edit: BlogEdit,
  show: BlogShow,
  icon: AssignmentRounded,
};

export default blog;
