import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";


const ErrorPageHeader = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const history = useHistory();
    const handlerOpenNav = () => {
        setIsMenuOpen((prev) => !prev);
    };
    const handlerClickLogo = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        history.push("/")
    }
    return (
       <nav className="nav-menu">
            <label htmlFor="check" className="checkbtn" onClick={handlerOpenNav}>
                <img
                     src={`${window.location.origin}/images/menuIcon/${isMenuOpen ? "close" : "menu"}.svg`}
                    alt="menu-icon"
                    className="closed-menu-icon"
                />
            </label>
            <div className="menu-logo-icon">
                <img
                    onClick={handlerClickLogo}
                    src={`${process.env.REACT_APP_BACKEND_PORT}/logo.webp`}
                    alt="Source-Code-logo"
                    className="logo source_code_logo"
                    loading="lazy"
                />
                <div id="smart-menu" className={isMenuOpen ? "closeMenuColor" : "openMenuColor"}>
                    <ul id="nav-list" className={isMenuOpen ? "closeMenu" : "openMenu"}>
                        <li data-nav-item="nav-item" className="nav-item">
                            <Link
                                to={{ pathname: "/", state: { scrollToElementName: "home" } }}>
                                <img src={`${window.location.origin}/images/menuIcon/home.svg`} alt="home" height="20px" width="20px" />Գլխավոր
                            </Link>
                        </li>
                        <li data-nav-item="nav-item" className="nav-item">
                            <Link
                                className="scroll-item nav-link"
                                to={{ pathname: "/", state: { scrollToElementName: "courses" } }}>
                                <img src={`${window.location.origin}/images/menuIcon/open-book.svg`} alt="book" height="20px" width="20px" />Դասընթացներ
                            </Link>
                        </li>
                        <li data-nav-item="nav-item" className="nav-item">
                            <Link
                                className="scroll-item nav-link"
                                to={{ pathname: "/", state: { scrollToElementName: "FAQ" } }}>
                                <img src={`${window.location.origin}/images/menuIcon/question.svg`} alt="question" height="20px" width="20px" />ՀՏՀ
                            </Link>
                        </li>
                        <li data-nav-item="nav-item" className="nav-item blog-icon">
                            <Link
                                className="scroll-item nav-link blog-icon"
                                to={{ pathname: "/", state: { scrollToElementName: "blog" } }}>
                                <img src={`${window.location.origin}/images/menuIcon/blogger.svg`} alt="blog" height="20px" width="20px" />Բլոգ
                            </Link>
                        </li>
                        <li data-nav-item="nav-item" className="nav-item">
                            <Link
                                className="scroll-item nav-link"
                                to={{ pathname: "/", state: { scrollToElementName: "about_us" } }}>
                                <img src={`${window.location.origin}/images/menuIcon/business-card.svg`} alt="aboutUs" height="20px" width="20px" />Մեր մասին</Link>
                        </li>
                        <li data-nav-item="nav-item" className="nav-item">
                            <Link
                                className="scroll-item nav-link"
                                to={{ pathname: "/", state: { scrollToElementName: "our_team" } }}>
                                <img src={`${window.location.origin}/images/menuIcon/group.svg`} alt="teams" height="20px" width="20px" />Մեր թիմը
                            </Link>
                        </li>
                        <li data-nav-item="nav-item" className="nav-item">
                            <Link
                                className="scroll-item nav-link"
                                to={{ pathname: "/", state: { scrollToElementName: "contact_us" } }}>
                                <img src={`${window.location.origin}/images/menuIcon/phone-call.svg`} alt="phone" height="20px" width="20px" />Կապ մեզ հետ
                            </Link>
                        </li>
                   </ul>
                </div>
            </div>
        </nav>
    );
};

export default ErrorPageHeader;
