import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios"


import Footer from "../../components/footer";
import AboutCourse from "../../components/moreAboutCourses/aboutCourse";
import CourseHeader from "../../components/moreAboutCourses/courseHeader";
import ErrorPage from "../../components/errorPage"
import MessnegerChat from "../../components/messengerChat"

import eventsCancel from "../../pageEvents"

import "./pages.css"


const CoursePage = () => {
  const [data, setData] = useState({
    settings: [],
    course: {
      tutors: [],
      tutorsInfo: [],
      languages: [],
      notes: [],
    }
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [progressing, setProgressing] = useState(0)
  const { title } = useParams();
  const history = useHistory();


  useEffect(() => {
    eventsCancel()
    let ignore = false;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    async function fetchData() {
      setIsError(false);
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_PORT}/courses/${title.toLowerCase()}`, {
          cancelToken: source.token,
          timeout: 10000,
          onDownloadProgress: (progressEvent) => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgressing(percentCompleted)
          }
        })

        if (response.status < 200 || response.status >= 300) {
          throw new Error();
        }

          if (!response.data.course || !response.data.course.available) {
          history.push({ pathname: "/", state: { scrollToElementName: "courses" } });
        } else if (!ignore) setData(response.data);
      } catch (error) {
        if (error.message === "Request aborted") return;
        if (error.message === "AbortError") return;
        setIsError(true);
      }
      setIsLoading(false);
    }
    fetchData();
    return () => {
      source.cancel('AbortError');
      ignore = true;
    };
  }, [title, history]);

  if (isError) return <ErrorPage />;

  return (
    <div className="global-style">
      <CourseHeader progressing={progressing} />
      <AboutCourse  {...{ course: data.course, isLoading }} />
      <Footer {...{ settings: data.settings, isLoading }} />
      <MessnegerChat />
    </div>
  );
};

export default CoursePage;
