import { cacheDataProviderProxy } from "react-admin";

import simpleRestDataProvider from "./simpleRestDataProvider"
import handleUpdateFilde from "./handleUpdateFilde"
import updateCourses from "./updateCourses"
import createSettings from "./createSettings"
import updateSettings from "./updateSettings"

const Authorization = `Bearer ${JSON.parse(localStorage.getItem("auth"))}`;



const DataProvider = {
  ...simpleRestDataProvider,
  create:  (resource, params) => {
    let checkLogos = null;
    let logos = null;
    if (
      resource === "admin/FAQs" ||
      (resource === "admin/settings" && params.data.category === "setting") ||
      typeof params.data.picture === "string"
    ) {
      return simpleRestDataProvider.create(resource, params);
    }

    if (resource === "admin/courses") {
      logos = params.data.languages.filter((e) => typeof e.logo === "string");
      checkLogos = logos.length === params.data.languages.length;
    }

    const file = new FormData();

    if (resource === "admin/settings") {
      return createSettings(resource, params, file);
    }

    if (typeof params.data.picture !== "string") {
      file.append("file", params.data.picture.rawFile);
    }

    if (resource === "admin/courses" && !checkLogos) {
      params.data.languages.forEach((e) => {
        file.append("logos", e.logo.rawFile);
      });
    }

    return fetch(
      `${process.env.REACT_APP_BACKEND_PORT}/${resource}ImageUpload`,
      {
        headers: {
          Authorization,
        },
        method: "POST",
        body: file,
      }
    )
      .then((response) => {
        if (response.status === 500) throw Error(response.statusText);
        return response.json();
      })
      .then((res) => {
        const picture = res.fileName ? res.fileName : params.data.picture;
        const data = {
          ...params.data,
          picture,
        };
        if (res.logos !== undefined && logos?.length === 0) {
          for (let i = 0; i < res.logos.length; i++) {
            data.languages[i].logo = res.logos[i];
          }
        } else if (res.logos !== undefined) {
          res.logos.forEach((newLogo) => {
            data.languages.forEach((e) => {
              if (e.logo.rawFile instanceof File) e.logo = newLogo;
            });
          });
        }
        return simpleRestDataProvider.create(resource, {
          ...params,
          data,
        });
      })
      .catch((error) => Promise.reject(error));
  },

  update: (resource, params) => {
    const { data, previousData } = params;
    if (resource !== "admin/settings") {
      const updatedData = handleUpdateFilde(data, previousData);
      params.data = updatedData;
    }

    if (resource === "admin/FAQ") {
      return simpleRestDataProvider.update(resource, params);
    }

    const file = new FormData();

    if (resource === "admin/settings") {
      return updateSettings(resource, params, file);
    }

    if (resource === "admin/courses") {
      return updateCourses(resource, params, file);
    }
    if (!(data?.picture?.rawFile instanceof File)) {
      return simpleRestDataProvider.update(resource, params);
    }
    file.append("file", data.picture.rawFile);

    return fetch(
      `${process.env.REACT_APP_BACKEND_PORT}/${resource}ImageUpload`,
      {
        method: "POST",
        headers: {
          Authorization,
        },
        body: file,
      }
    )
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((res) => {
        return simpleRestDataProvider.update(resource, {
          ...params,
          data: {
            ...params.data,
            picture: res.fileName,
          },
        });
      })
      .catch((error) => Promise.reject(error));
  },
};

export default cacheDataProviderProxy(DataProvider);
