import React from "react";
import { Controller, useWatch } from "react-hook-form";
import { RadioGroup, FormControlLabel, FormControl, FormHelperText } from "@material-ui/core";

import StyledRadio from "./StyledRadio";

const Radios = ({ fildInfo, errors, RadioGroupName, control }) => {

  const value = useWatch({
    control,
    name: RadioGroupName,
  });
  
  return (
    <Controller
      as={
        <FormControl
          component="fieldset"
          error={errors[RadioGroupName] ? true : false}
        >
          <RadioGroup name={RadioGroupName} value={value} >
            {fildInfo.map((e) => (
              <FormControlLabel
                key={e}
                value={e}
                control={<StyledRadio />}
                label={e}
              />
            ))}
          </RadioGroup>
          <FormHelperText>
            {errors[RadioGroupName] ? "Պարտադիր լրացման դաշտ" : ""}
          </FormHelperText>
        </FormControl>
      }
      rules={{ required: true }}
      name={RadioGroupName}
      control={control}
    />
  );
};

export default Radios;
