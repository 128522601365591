import React from "react";

const TermsAndConditions = () => {
  return (
    <>
      <div className="terms-and-conditions">
        <p>
          Եթե դուք ապրում եք մարզում, Ձեզ համար դժվար է հաճախել առկա
          դասընթացներին, ապա առաջարկում ենք սովորել{" "}
          <span>ծրագրավորման հիմնարար գիտելիքներ</span> մեզ հետ՝ առցանց
          հարթակում։ Մեր կայքում ներկայացված բոլոր դասընթացները կազմակերպվում են
          նաև <span>առցանց տարբերակով։</span>
        </p>
        <h4>Ինչպե՞ս են կազմակերպվում առցանց դասընթացները</h4>
        <p>
          Դրանք կազմակերպվում են ZOOM վիդեո կոնֆերանս ծրագրային հավելվածի
          միջոցով։ Վերջինս իրականացվում է վիդեոզանգի միջոցով, օգտագործողի
          համակարգչային էկրանով կիսվելու և հեռակառավարման հնարավորությամբ։
        </p>
        <p>
          Օնլայն դասընթացներն իրենց արդյունավետությամբ, ծրագրային կազմով և տևողությամբ
          չեն զիջում կամ տարբերվում առկա դասերից։
        </p>
        <p className="information">
          {
            "Օնլայն դասընթացին գրանցվելու համար, դասընթացների ցանկից գտեք նախընտրելին և սեղմեք <<Գրանցվել>> կոճակը:"
          }
        </p>
      </div>
      <div className="border"></div>
    </>
  );
};

export default TermsAndConditions;
