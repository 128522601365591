import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';


const ListItemStudent = (props) => {
    const { fullName, handleClickOpen, password } = props;
    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            <ListItem button style={{ borderBottom: "1px double darkblue", width: "50%" }} onClick={handleClickOpen}>
                <ListItemText primary={fullName} />
            </ListItem>
            <ListItem style={{ borderBottom: "1px double darkblue", width: "30%" }} >
                <ListItemText disableTypography={true} primary={`password - ${password}`} />
            </ListItem>
        </div>
    );
}


export default ListItemStudent
