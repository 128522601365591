import simpleRestDataProvider from "./simpleRestDataProvider"

export default function updateSettings(resource, params, file) {
    const { data } = params;

    if (data.category === "setting") {
        return simpleRestDataProvider.update(resource, params);
    }

    const { category, logoPicture, logoIcon, bannerPicture } = data
    if (category === "logo") {
        if (typeof logoPicture !== "string") {
            const formatLogoPicture = logoPicture.rawFile.name.match(
                /\.(.*)$/i
            );
            const logoName = `logo${formatLogoPicture[0]}`;
            file.append("logo", logoPicture.rawFile, logoName);
        }
        if (typeof logoIcon !== "string") {
            const formatLogoIcon = logoIcon.rawFile.name.match(
                /\.(png)$/i
            );
            const logoIconName = `icon-img${formatLogoIcon[0]}`;
            file.append("logo_icon", logoIcon.rawFile, logoIconName);
        }
    } else if (category === "banner" && typeof bannerPicture === "string") {
        return simpleRestDataProvider.update(resource, params);
    } else if (category === "banner") {
        const formatBannerImg = bannerPicture.rawFile.name.match(
            /\.(.*)$/i
        );
        const bannerPictureName = `${Date.now()}_banner${formatBannerImg[0]}`;
        file.append("banner", bannerPicture.rawFile, bannerPictureName);
    }

    return fetch(`${process.env.REACT_APP_BACKEND_PORT}/${resource}ImageUpload`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
        },
        body: file,
    })
        .then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then((res) => {
            if (category === "logo") {
                if (res.logo) {
                    data.logoPicture = res.logo;
                }
                if (res.logoIcon) {
                    data.logoIcon = res.logoIcon;
                }
            } else {
                data.bannerPicture = res.bannerImg;
            }
            return simpleRestDataProvider.update(resource, {
                ...params,
                data,
            });
        })
        .catch((error) => Promise.reject(error));
}