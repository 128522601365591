import * as React from "react";
import { ImageField } from "react-admin";

const PreviewImage = ({ record, source }) => {
  if (record.rawFile === undefined && record.url) {
    record = {
      [source]: `${process.env.REACT_APP_BACKEND_PORT}/${record.url}`,
    };
  }
  return <ImageField record={record} source={source} />;
};

export default PreviewImage;
