import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  ListButton,
  CloneButton,
  TopToolbar,
  EditButton,
} from "react-admin";

import FAQTitle from "./FAQTitle";

const FAQShowActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <CloneButton className="button-clone" basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const FAQShow = (props) => {
  return (
    <Show {...props} title={<FAQTitle />} actions={<FAQShowActions />}>
      <SimpleShowLayout>
        <TextField source="question" />
        <TextField source="answer" />
      </SimpleShowLayout>
    </Show>
  );
};

export default FAQShow;
