import * as React from "react";
import {
  CloneButton,
  Edit,
  required,
  ShowButton,
  SimpleForm,
  TextInput,
  TopToolbar,
  ImageInput,
  ListButton,
} from "react-admin";

import ReviewTitle from "./ReviewTitle";
import { PreviewImage } from "../common/components";
import { formatImage } from "../common/formatImage";

const ReviewEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <CloneButton className="button-clone" basePath={basePath} record={data} />
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);

const ReviewEdit = (props) => (
  <Edit title={<ReviewTitle />} actions={<ReviewEditActions />} {...props}>
    <SimpleForm>
      <TextInput source="fullName" validate={required()} />
      <TextInput
        source="review"
        validate={required()}
        fullWidth
        multiline
        resettable
      />
      <ImageInput
        format={formatImage}
        validate={required()}
        source="picture"
        accept="image/png, image/jpg, image/jpeg"
        maxSize={1000000}
      >
        <PreviewImage source="url" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

export default ReviewEdit;
