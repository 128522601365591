import React, { useEffect } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import TeamMemberCard from "../teamMemberCard"

import "./team.css";


const textVariants = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: -100 }
};

const Team = ({ teams, isLoading }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const info = (isLoading
    ? Array.from(new Array(8))
    : teams).map((item, i) => {
      return (
        !item ? <div key={i} style={{ marginBottom: "25px" }}>
          <Skeleton variant="rect" width={300} height={350} />
        </div> :
          <TeamMemberCard teamMember={item} key={item._id} />
      );
    });
  return (
    <aside>
      <div className="size">
        <motion.h2
          className="meet"
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={textVariants}>
          Ծանոթացեք մեր պրոֆեսիոնալ մասնագետների հետ
        </motion.h2>
        <div className="image-area" >
          {info}
        </div>
      </div>
    </aside>
  );
};

export default Team;
