import React from "react"
import { withStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

import SocialLink from '../socialLink'

import './socialMedia.css';



const CssSkeleton = withStyles({
  root: {
    backgroundColor: "lightgrey"
  },
})(Skeleton);


const SocialMedia = ({ settings, isLoading }) => {
  const socialLinks = {}
  settings.forEach(element => {
    socialLinks[element.settingName] = element.value
  });
  return (
    <div className="social-link social-white">
      {isLoading ? Array.from(new Array(5)).map((_, i) =>
        <CssSkeleton key={i} variant="circle" width={55} height={55} />) :
        <SocialLink socialLinks={socialLinks} />
      }
    </div>
  )
}

export default SocialMedia;