import * as React from "react";
import {
  Create,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  TopToolbar,
  ListButton,
  useInput,
  ImageInput,
  ArrayInput,
  SimpleFormIterator,
  RadioButtonGroupInput,
} from "react-admin";

import { PreviewImage } from "../common/components";
import { formatImage } from "../common/formatImage";

const SettingCreateToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="save" redirect="list" submitOnEnter={false} />
    <SaveButton
      label="save and show"
      variant="text"
      redirect="show"
      submitOnEnter={true}
    />
  </Toolbar>
);

const SettingCreateActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

const CategoryOptionInput = (props) => {
  const { input } = useInput(props);
  let form = null;
  if (input.value === "setting") {
    form = (
      <div>
        <TextInput source="value" validate={required()} label="option value" />
      </div>
    );
  } else if (input.value === "logo") {
    form = (
      <>
        <ImageInput
          format={formatImage}
          validate={required()}
          placeholder="Drop a picture to upload, or click to select it. (upload only webp format and maxSize 30 KB)"
          source="logoPicture"
          accept="image/webp"
          maxSize={30000}
        >
          <PreviewImage source="url" />
        </ImageInput>
        <ImageInput
          format={formatImage}
          validate={required()}
          placeholder="Drop a picture to upload, or click to select it. (upload only png format and maxSize 30 KB)"
          source="logoIcon"
          accept="image/png"
          maxSize={30000}
        >
          <PreviewImage source="url" />
        </ImageInput>
      </>
    );
  } else if (input.value === "banner") {
    form = (
      <>
        <ImageInput
          format={formatImage}
          validate={required()}
          placeholder="Drop a picture to upload, or click to select it. (maxSize 300 KB)"
          source="bannerPicture"
          accept="image/*"
          maxSize={300000}
        >
          <PreviewImage source="url" />
        </ImageInput>
        <ArrayInput
          source="bannerTexts"
          label="Banner Texts"
          validate={required()}
        >
          <SimpleFormIterator>
            <TextInput
              source="bannerText"
              label="Banner text"
              multiline
              validate={required()}
              fullWidth
              resettable
            />
          </SimpleFormIterator>
        </ArrayInput>
      </>
    );
  }
  const initialValue = input.value === "setting" ? "" : input.value;
  const disabledValue = input.value === "setting" ? false : true;

  return (
    <>
      <RadioButtonGroupInput
        source="category"
        label="Category Setting"
        choices={[
          { id: "setting", name: "Setting" },
          { id: "logo", name: "Logo" },
          { id: "banner", name: "Banner" },
        ]}
      />
      <br />
      <TextInput
        validate={required()}
        source={`${input.value}Name`}
        disabled={disabledValue}
        initialValue={initialValue}
        label="option name"
      />
      <br />
      {form}
    </>
  );
};

const SettingCreate = (props) => {
  return (
    <Create {...props} actions={<SettingCreateActions />}>
      <SimpleForm toolbar={<SettingCreateToolbar />}>
        <CategoryOptionInput
          initialValue="setting"
          source="category"
          label="option value"
        />
      </SimpleForm>
    </Create>
  );
};

export default SettingCreate;