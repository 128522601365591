import * as React from "react";
import {
  TopToolbar,
  Edit,
  ShowButton,
  SimpleForm,
  TextInput,
  required,
  ListButton,
  ImageInput,
  ArrayInput,
  SimpleFormIterator,
  RadioButtonGroupInput,
  FunctionField
} from "react-admin";
import SettingTitle from "./SettingTitle";

import { PreviewImage } from "../common/components";
import { formatImage } from "../common/formatImage";


const SettingEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);


const CategoryOptionInput = (record) => {
  let form = null;
  if (record.category === "setting") {
    form = (
      <div>
        <TextInput source="value" validate={required()} label="option value" />
      </div>
    );
  } else if (record.category === "logo") {
    form = (
      <>
        <ImageInput
          format={formatImage}
          validate={required()}
          placeholder="Drop a picture to upload, or click to select it. (upload only webp format and maxSize 30 KB)"
          source="logoPicture"
          accept="image/webp"
          maxSize={30000}
        >
          <PreviewImage source="url" />
        </ImageInput>
        <ImageInput
          format={formatImage}
          validate={required()}
          placeholder="Drop a picture to upload, or click to select it. (upload only png format and maxSize 30 KB)"
          source="logoIcon"
          accept="image/png"
          maxSize={30000}
        >
          <PreviewImage source="url" />
        </ImageInput>
      </>
    );
  } else if (record.category === "banner") {
    form = (
      <>
        <ImageInput
          format={formatImage}
          validate={required()}
          source="bannerPicture"
          placeholder="Drop a picture to upload, or click to select it. (maxSize 300 KB)"
          accept="image/*"
          maxSize={300000}
        >
          <PreviewImage source="url" />
        </ImageInput>
        <ArrayInput
          source="bannerTexts"
          label="Banner Texts"
          validate={required()}
        >
          <SimpleFormIterator>
            <TextInput
              source="bannerText"
              label="Banner text"
              multiline
              validate={required()}
              fullWidth
              resettable
            />
          </SimpleFormIterator>
        </ArrayInput>
      </>
    );
  }

  //const initialValue = record.category === "setting" ? "" : record.category;
  //const disabledValue = record.category === "setting" ? false : true;

  return (
    <>
      <RadioButtonGroupInput
        source="category"
        label="Category Setting"
        disabled
        choices={[
          { id: "setting", name: "Setting" },
          { id: "logo", name: "Logo" },
          { id: "banner", name: "Banner" },
        ]}
      />
      <br />
      <TextInput
        validate={required()}
        source={`${record.category}Name`}
        disabled={true}
        label="option name"
      />
      <br />
      {form}
    </>
  );
};


const SettingEdit = (props) => (
  <Edit title={<SettingTitle />} actions={<SettingEditActions />} {...props}>
    <SimpleForm>
      <FunctionField label="" source="category" render={record => CategoryOptionInput(record)} />
    </SimpleForm>
  </Edit>
);

export default SettingEdit;
