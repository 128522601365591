import React, { useState, forwardRef } from "react";
import { useForm } from "react-hook-form";
import { Button, Dialog, Slide, makeStyles } from "@material-ui/core";

import DialogContent from "./DialogContent";
import DialogActions from "./DialogActions";
import DialogTitle from "./DialogTitle";
import AlertDialog from "./AlertDialog";
import RegisterFormForCourses from "./RegisterFormForCourses";
import ModalCoursesOnlineInfo from "./ModalCoursesOnlineInfo";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide ref={ref} {...props} />;
});

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",
  },
  paperWidthXs: { width: "500px" },
  paper: {
    borderBottom: "8px solid #123B85 !important",
  },
});

const defaultValues = {
  fullName: "",
  typeCourse: "Առկա",
  studentsNumber: "Խմբային",
  message: "",
  phone: ""
}

const ModalRegisterCourses = ({ open, setOpen, courseInfo = {}, Id }) => {
  const [openCoursesOnline, setOpenCoursesOnline] = useState(false);
  const { register, handleSubmit, errors, reset, control, watch } = useForm({
    mode: "onBlur",
    defaultValues
  });

  const [messageRespons, setMessageRespons] = useState("");
  const [openAlertDialog, setOpenAlertDialog] = useState(false);

  const values = watch(["studentsNumber", "typeCourse"])

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const handleClickOpen = () => {
    setOpenCoursesOnline(true);
  };
  const altVersion = <p>
    Շնորհակալություն մեզ վստահելու համար։
    Ցավոք Ձեր հայտը չի ուղարկվել տեխնիկական խնդիրների պատճառով։
    Մեզ հետ կարող եք կապ հաստատել նաև 
     <a href="https://www.facebook.com/sourcecode.am/" target="_blank" rel="noopener noreferrer">
        {" այստեղ։"}
     </a>
  </p>

  const handleOnSubmit = (data) => {
    data = { ...data, courseTitle: courseInfo.title };
    setOpen(false);
    reset();
    fetch(`${process.env.REACT_APP_BACKEND_PORT}/registerCourse`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error();
        }
        setMessageRespons("Շնորհակալություն մեզ վստահելու համար։ Ձեր հայտը հաջողությամբ ուղարկվել է։");
        setOpenAlertDialog(true);
      })
      .catch(() => {
        setMessageRespons(altVersion);
        setOpenAlertDialog(true);
      });
  };

  let price
  if (values.typeCourse === "Առկա" && values.studentsNumber === "Խմբային") {
    price = courseInfo.availableGroupPrice
  } else if (values.typeCourse === "Առցանց" && values.studentsNumber === "Խմբային") {
    price = courseInfo.onlineGroupPrice
  } else if (values.typeCourse === "Առկա" && values.studentsNumber === "Անհատական") {
    price = courseInfo.availableIndividualPrice
  } else if (values.typeCourse === "Առցանց" && values.studentsNumber === "Անհատական") {
    price = courseInfo.onlineIndividualPrice
  }

  const classes = useStyles();
  return (
    <div>
      <Dialog
        TransitionComponent={Transition}
        keepMounted
        transitionDuration={500}
        onClose={handleClose}
        open={open}
        fullWidth
        maxWidth="xs"
        classes={{
          scrollPaper: classes.scrollPaper,
          paperWidthXs: classes.paperWidthXs,
          paper: classes.paper,
        }}
      >
        <DialogTitle onClose={handleClose}>Գրանցվել դասընթացին</DialogTitle>
        <DialogContent dividers>
          <RegisterFormForCourses
            {...{
              handleOnSubmit,
              register,
              handleSubmit,
              errors,
              control,
              Id,
              values
            }}
          />
          <p className="info-link">

            Առցանց դասընթացի <span onClick={handleClickOpen}>մանրամասներ</span>
          </p>
          <p className="price-for-courses">Դասընթացի արժեքը կկազմի <span>{`${price}`}</span> դր․/ամսական</p>

        </DialogContent>
        <DialogActions>
          <Button type="submit" form={Id} color="primary">
            Ուղարկել
          </Button>
          <Button onClick={handleClose} color="primary">
            Չեղարկել
          </Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        {...{ openAlertDialog, setOpenAlertDialog, messageRespons }}
      />
      <ModalCoursesOnlineInfo
        {...{ open: openCoursesOnline, setOpen: setOpenCoursesOnline }}
      />
    </div>
  );
};

export default ModalRegisterCourses;
