import React, { useEffect } from 'react';
import { Player } from 'video-react';
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Image } from "react-image-and-background-image-fade";

import './aboutUs.css'

const BoxVariantsDelay = {
    visible: { opacity: 1, transition: { duration: 1, delay: 1 } },
    hidden: { opacity: 0, }
};
const BoxVariants = {
    visible: { opacity: 1, transition: { duration: 1 } },
    hidden: { opacity: 0, }
};

const AboutUs = () => {
    const controls = useAnimation();
    const [ref, inView] = useInView({ triggerOnce: true });

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

    return (
        <section className="about-us">
            <div className="who-we-are">
                <motion.div
                    ref={ref}
                    className="who-we-are-more"
                    animate={controls}
                    initial="hidden"
                    variants={BoxVariantsDelay}>
                    <h3>Մեր մասին</h3>
                    <p> Սորս կոդ ՍՊ ընկերությունը վեբ և մոբայլ ծրագրավորման ծառայություններ
                        և ծրագրավորման հիմնարար դասընթացներ մատուցող  կազմակերպություն է։
                        Մենք համագործակցում ենք ինչպես Հայաստանյան, այնպես էլ Հայաստանի
                        սահմաններից դուրս գտնվող կազմակերպությունների և անհատ
                        ձեռնարկատերերի հետ, որոնք զբաղվում են տեղեկատվական տեխնոլոգիաների
                        մշակմամբ և զարգացմամբ։</p>
                    <div className="about-us-text">
                        <p><Image
                            src="images/checked.svg" alt="checked"
                            lazyLoad
                        />
                            Մեր նպատակն է կրթել ու զարգացնել ժամանակակից ստանդարտներին համապատասխան,
                            տեղեկատվական տեխնոլոգիայի ոլորտում մրցունակ և պահանջված մասնագետներ։</p>
                    </div>
                    <div className="bordered"></div>
                </motion.div>
                <motion.div
                    className="certeficate"
                    animate={controls}
                    initial="hidden"
                    variants={BoxVariants}>
                    <img src={inView ? "images/groupImage.jpg" : "#"} alt="serteficate" />
                </motion.div>
            </div>
            <div className="about-us-parent">
                <motion.div
                    className="about-us-parent-left"
                    animate={controls}
                    initial="hidden"
                    variants={BoxVariants}>
                    <Player src={inView ? "images/video/sourcecode.mov" : "#"} autoPlay loop muted />
                </motion.div>
                <div className="about-us-parent-right">
                    <motion.div
                        className="imp"
                        animate={controls}
                        initial="hidden"
                        variants={BoxVariantsDelay}>
                        <p>
                            <span>Վստահեք</span>  ձեր մասնագիտական ուղին  մեր  <span>փորձառու թիմին, </span>
                            մենք կզինենք  Ձեզ ծրագրավորման հիմնարար գիտելիքներով`
                            <span>դուք կլինեք պահանջված  մասնագետներ</span>  տեղեկատվական տեխնոլոգիաների ոլորտում
                        </p>
                        <div className="fg">
                            <p> <img src="images/checked.svg" alt="checked" loading="lazy" />
                                Մեր դասընթացներն անցկացվում են արդյունավետ մեթոդաբանությամբ
                                և մշտապես թարմացվող ծրագրերով։
                            </p>
                        </div>
                    </motion.div>
                </div>
            </div>
        </section >
    )
}

export default React.memo(AboutUs)