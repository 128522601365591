import React  from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-scroll";

import SocialLink from '../../socialLink'

import "../menu.css";



const SidebarNav = ({ isMenuOpen, settings, setIsMenuOpen }) => {
  const history = useHistory();

  const handlerOpenNav = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const handlerCloseNav = () => {
    if (!isMenuOpen) return
    setIsMenuOpen(false);
  }

  const socialLinks = {}
  settings.forEach(element => {
    socialLinks[element.settingName] = element.value
  });


  const handlerClickLogo = () => {
    history.push("/")
  }


  return (
       <nav className="nav-menu">
        <label htmlFor="check" className="checkbtn" onClick={handlerOpenNav}>
          <img
            src={`images/menuIcon/${isMenuOpen ? "close" : "menu"}.svg`}
            alt="menu-icon"
            className="closed-menu-icon"
          />
        </label>

        <div className="menu-logo-icon">
          <img
            onClick={handlerClickLogo}
            src={`${process.env.REACT_APP_BACKEND_PORT}/logo.webp`}
            alt="Source-Code-logo"
            className="logo source_code_logo"
          />
          <div id="smart-menu" className={isMenuOpen ? "closeMenuColor" : "openMenuColor"}>
            <ul id="nav-list" className={isMenuOpen ? "closeMenu" : "openMenu"}>

              <li data-nav-item="nav-item" className="nav-item">
                <Link
                  activeClass="active"
                  to="home"
                  spy={true}
                  smooth={true}
                  duration={0}
                  offset={-100}
                  onClick={handlerCloseNav}
                >
                  <img src="images/menuIcon/home.svg" alt="home"  height="20px" width="20px" />Գլխավոր
                </Link>
              </li>
              <li data-nav-item="nav-item" className="nav-item">
                <Link
                  activeClass="active"
                  className="scroll-item nav-link"
                  to="courses"
                  spy={true}
                  smooth={true}
                  duration={0}
                  offset={-80}
                  onClick={handlerCloseNav}
                >
                  <img src="images/menuIcon/open-book.svg" alt="book" height="20px" width="20px" />Դասընթացներ
                </Link>
              </li>
              <li data-nav-item="nav-item" className="nav-item">
                <Link
                  className="scroll-item nav-link"
                  activeClass="active"
                  to="FAQ"
                  spy={true}
                  smooth={true}
                  duration={0}
                  offset={-80}
                  onClick={handlerCloseNav}
                >
                  <img src="images/menuIcon/question.svg" alt="question"  height="20px" width="20px" />ՀՏՀ
                </Link>
              </li>
              <li data-nav-item="nav-item" className="nav-item blog-icon">
                <Link
                  className="scroll-item nav-link blog-icon"
                  activeClass="active"
                  to="blog"
                  spy={true}
                  smooth={true}
                  duration={0}
                  offset={-80}
                  onClick={handlerCloseNav}
                >
                  <img src="images/menuIcon/blogger.svg" alt="blog"  height="20px" width="20px" />Բլոգ
                </Link>
              </li>
              <li data-nav-item="nav-item" className="nav-item">
                <Link
                  className="scroll-item nav-link"
                  activeClass="active"
                  to="about_us"
                  spy={true}
                  smooth={true}
                  duration={0}
                  offset={-50}
                  onClick={handlerCloseNav}
                >
                  <img src="images/menuIcon/business-card.svg" alt="aboutUs"  height="20px" width="20px" />Մեր մասին
                </Link>
              </li>
              <li data-nav-item="nav-item" className="nav-item">
                <Link
                  className="scroll-item nav-link"
                  activeClass="active"
                  to="our_team"
                  spy={true}
                  smooth={true}
                  duration={0}
                  offset={-80}
                  onClick={handlerCloseNav}
                >
                  <img src="images/menuIcon/group.svg" alt="teams"  height="20px" width="20px" />Մեր թիմը
                </Link>
              </li>
              <li data-nav-item="nav-item" className="nav-item">
                <Link
                  className="scroll-item nav-link"
                  activeClass="active"
                  to="contact_us"
                  spy={true}
                  smooth={true}
                  duration={0}
                  offset={-80}
                  onClick={handlerCloseNav}
                >
                  <img src="images/menuIcon/phone-call.svg" alt="phone"  height="20px" width="20px" />Կապ մեզ հետ
                </Link>
              </li>
              <li>
                <div className="menu-social" >
                  <div id="social-media" className="social-media">
                    <SocialLink socialLinks={socialLinks} />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
  );
};

export default SidebarNav;
