import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios"

import Blogheader from "../../components/moreAboutBlog/blogHeader";
import AboutBlog from "../../components/moreAboutBlog/aboutBlog";
import Footer from "../../components/footer";
import ErrorPage from "../../components/errorPage"
import MessnegerChat from "../../components/messengerChat"

import eventsCancel from "../../pageEvents"

import './pages.css'

const BlogPage = () => {
  const [data, setData] = useState({ blogs: [], settings: [], demoBlog: {} });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [progressing, setProgressing] = useState(0)
  const history = useHistory();
  const { title } = useParams();

  useEffect(() => {
    let ignore = false;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    eventsCancel()
    async function fetchData() {
      setIsError(false);
      setIsLoading(true);
      setProgressing(0)
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_PORT}/blogs/${title.toLowerCase()}`, {
          cancelToken: source.token,
          timeout: 10000,
          onDownloadProgress: (progressEvent) => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgressing(percentCompleted)
          }
        })

        if (response.status < 200 || response.status >= 300) {
          throw new Error();
        }

        if (!response.data.demoBlog) {
          history.push({ pathname: "/", state: { scrollToElementName: "blog" } });
        } else if (!ignore) setData(response.data);
      } catch (error) {
        if (error.message === "Request aborted") return;
        if (error.message === "AbortError") return;
        setIsError(true);
      }
      setIsLoading(false);
    }
    fetchData();
    return () => {
      source.cancel('AbortError');
      ignore = true;
    };
  }, [history, title]);

  if (isError) return <ErrorPage />;

  return (
    <div className="global-style">
      <Blogheader progressing={progressing} />
      <AboutBlog {...{ ...data, setData, isLoading, setProgressing }} />
      <Footer {...{ settings: data.settings, isLoading }} />
      <MessnegerChat />
    </div>
  );
};

export default BlogPage;
