import React from "react";

import './advantages.css';


const Advantages = () => {
  return (
    <>
      <section className="advant">
        <div className="advantages-parent">
          <div>
            <div className="advantages-icon-border">
              <img src="images/advantages/online-lesson.svg" alt="online-lesson" loading="lazy" height="60px" width=" 60px" />
            </div>
            <div className="advantages-border"></div>
            <p>Առկա և առցանց դասընթացներ</p>
          </div>

          <div>
            <div className="advantages-icon-border">
              <img src="images/advantages/passage-of-time.svg" alt="passage-of-time" loading="lazy" height="60px" width=" 60px"/>
            </div>
            <div className="advantages-border"></div>
            <p>Ժամային ճկուն գրաֆիկ</p>
          </div>

          <div>
            <div className="advantages-icon-border">
              <img src="images/advantages/presentation.svg" alt="presentation" loading="lazy" height="60px" width=" 60px"/>
            </div>
            <div className="advantages-border"></div>
            <p>Բարձր որակի դասընթացներ</p>
          </div>

          <div>
            <div className="advantages-icon-border">
              <img src="images/advantages/teaching.svg" alt="teaching" loading="lazy" height="60px" width=" 60px"/>
            </div>
            <div className="advantages-border"></div>
            <p>4-6 ուսանողից կազմված խմբեր</p>
          </div>
          <div>
            <div className="advantages-icon-border">
              <img src="images/advantages/cooperation.svg" alt="cooperation" loading="lazy" height="60px" width=" 60px"/>
            </div>
            <div className="advantages-border"></div>
            <p>Պրակտիկա և աշխատանք</p>
          </div>
        </div>
      </section>
    </>
  )
}

export default React.memo(Advantages)