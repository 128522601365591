import React, { useEffect, useState } from "react";
import MessengerCustomerChat from 'react-messenger-customer-chat';


const MessnegerChat = () => {
    const [show, setShow] = useState(false)
    useEffect(() => {
        const timeId = setTimeout(() => setShow(true), 10000)
        return () => {
            clearInterval(timeId)
        }
    })
    return (
        show ?
            <MessengerCustomerChat
                pageId={process.env.REACT_APP_PAGEID}
                appId={process.env.REACT_APP_APPID}
            /> : null
    )
}

export default React.memo(MessnegerChat)