import React from "react";
import { Image } from 'react-image-and-background-image-fade'

import "../team/team.css";

const TeamMemberCard = ({ teamMember }) => {

    return (
        <div className="carder" >
            <div className="image">
                <Image
                    src={`${process.env.REACT_APP_BACKEND_PORT}/${teamMember.picture}`}
                    alt={`${teamMember.fullName}`}
                    width="307px"
                    height="387px"
                    transitionTime="1s"
                    lazyLoad
                />
            </div>
            <div className="details">
                <div className="center">
                    <h1>{teamMember.fullName}</h1>
                    <span className="role">{teamMember.position}</span>
                    <p>{teamMember.description}</p>
                    <div className="team-border"></div>
                    <span className="like">{teamMember.hobby}</span>
                    <ul>
                        <li>
                            <a
                                href={teamMember.LinkedInUrl}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img src="../images/linkedin-team.svg" alt="linkedin" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>)
}


export default TeamMemberCard;
