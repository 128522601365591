import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  FunctionField,
  TopToolbar,
  ListButton,
  CloneButton,
  EditButton,
  UrlField,
} from "react-admin";

import TeamTitle from "./TeamTitle";

const PreviewImage = ({ record }) => (
  <img
    width={"50%"}
    src={`${process.env.REACT_APP_BACKEND_PORT}/${record.picture}`}
    alt={record.fullName}
  />
);

const TeamShowActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <CloneButton className="button-clone" basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const TeamShow = (props) => (
  <Show {...props} title={<TeamTitle />} actions={<TeamShowActions />}>
    <SimpleShowLayout>
      <TextField source="fullName" />
      <TextField source="position" />
      <TextField source="hobby" />
      <TextField source="description" />
      <UrlField source="LinkedInUrl" />
      <FunctionField
        label="picture"
        render={(record) => <PreviewImage record={record} />}
      />
    </SimpleShowLayout>
  </Show>
);

export default TeamShow;
