import React from "react";
import DialogActions from "@material-ui/core/DialogActions";
import { Typography, Dialog, Button, makeStyles } from "@material-ui/core";
import DialogContent from "./DialogContent";

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",
  },
  paper: {
    borderBottom: "5px solid #123B85 !important",
    padding:"10px 40px"
  }

})


const AlertDialog = ({
  openAlertDialog,
  setOpenAlertDialog,
  messageRespons,
}) => {
  const handleClose = () => {
    setOpenAlertDialog(false);
  };
  const classes = useStyles()
  return (
    <Dialog
      classes={{
        scrollPaper: classes.scrollPaper,
        paperWidthXs: classes.paperWidthXs,
        paper: classes.paper
      }}
      open={openAlertDialog}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
    >
      <DialogContent dividers>
        <Typography gutterBottom>{messageRespons}</Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
