import ImportContactsRoundedIcon from "@material-ui/icons/ImportContactsRounded";
import CourseCreate from "./CourseCreate";
import CourseEdit from "./CourseEdit";
import CourseList from "./CourseList";
import CourseShow from "./CourseShow";

const course = {
  list: CourseList,
  create: CourseCreate,
  edit: CourseEdit,
  show: CourseShow,
  icon: ImportContactsRoundedIcon,
};

export default course;
