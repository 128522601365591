import * as React from "react";
import {
  CloneButton,
  Edit,
  required,
  SelectInput,
  ShowButton,
  TextInput,
  TopToolbar,
  ImageInput,
  ListButton,
  SimpleForm,
  email,
} from "react-admin";

import UserTitle from "./UserTitle";
import { PreviewImage } from "../common/components";
import { formatImage } from "../common/formatImage";

const UserEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <CloneButton className="button-clone" basePath={basePath} record={data} />
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);

const UserEdit = (props) => (
  <Edit title={<UserTitle />} actions={<UserEditActions />} {...props}>
    <SimpleForm>
      <TextInput source="fullName" validate={required()} />
      <TextInput source="phone" validate={required()} />
      <TextInput source="email" validate={[required(), email()]} />
      <SelectInput
        source="role"
        validate={required()}
        choices={[
          { id: "user", name: "User" },
          { id: "teacher", name: "Teacher" },
        ]}
      />
      <ImageInput
        format={formatImage}
        validate={required()}
        source="picture"
        accept="image/png, image/jpg, image/jpeg"
        placeholder="Drop a picture to upload, or click to select it. (maxSize 500 KB)"
        maxSize={500000}
      >
        <PreviewImage source="url" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

export default UserEdit;
