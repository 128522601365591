import simpleRestDataProvider from "./simpleRestDataProvider"

export default function createSettings(resource, params, file) {
    const { data } = params;
    console.log(data)
    const { logoPicture, logoIcon, bannerPicture } = data

    if (data.category === "logo") {
        const formatLogoPicture = logoPicture.rawFile.name.match(
            /\.(webp)$/i
        );
        const formatLogoIcon = logoIcon.rawFile.name.match(
            /\.(png)$/i
        );
        const logoName = `logo${formatLogoPicture[0]}`;
        const logoIconName = `icon-img${formatLogoIcon[0]}`;
        file.append("logo", logoPicture.rawFile, logoName);
        file.append("logo_icon", logoIcon.rawFile, logoIconName);
    } else {
        const formatBannerImg = bannerPicture.rawFile.name.match(
            /\.(jpg|jpeg|tiff|png)$/i
        );
        const bannerPictureName = `${Date.now()}_banner${formatBannerImg[0]}`;
        file.append("banner", bannerPicture.rawFile, bannerPictureName);
    }

    return fetch(`${process.env.REACT_APP_BACKEND_PORT}/${resource}ImageUpload`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
        },
        body: file,
    })
        .then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then((res) => {
            const data = {
                ...params.data,
            };

            if (data.category === "logo") {
                data.logoPicture = res.logo;
                data.logoIcon = res.logoIcon;
            } else {
                data.bannerPicture = res.bannerImg;
            }

            return simpleRestDataProvider.create(resource, {
                ...params,
                data,
            });
        })
        .catch((error) => Promise.reject(error));
}