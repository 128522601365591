import React from "react";
import { withStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import "./countdown.css";

const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;

const timerProps = {
  isPlaying: true,
  size: 100,
  strokeWidth: 2,
};

const CssSkeleton = withStyles({
  root: {
    backgroundColor: "lightgrey"
  },
})(Skeleton);

const renderTime = (dimension, time) => {
  return (
    <div className="time-wrapper">
      <div className="time">{time}</div>
      <div>{dimension}</div>
    </div>
  );
};

const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
const getTimeDays = (time) => (time / daySeconds) | 0;

const CountDown = ({ data, isLoading }) => {
  const countdownDate = new Date(data).getTime();

  const now = new Date().getTime();
  const distance = countdownDate - now;

  const remainingTime = distance / 1000;
  const days = Math.ceil(remainingTime / daySeconds);
  const daysDuration = days * daySeconds;

  return (
    <div className="down">
      {isLoading ? Array.from(new Array(4)).map((_, index) => <CssSkeleton key={index} variant="circle" width={100} height={100} className="timer-cyrcle"/>) :
        <>
          <CountdownCircleTimer
            {...timerProps}
            colors={[["#FFFFFF"]]}
            trailColor={[["#00ffd6"]]}
            duration={daysDuration}
            initialRemainingTime={remainingTime}
          >
            {({ elapsedTime }) =>
              renderTime("օր", getTimeDays(daysDuration - elapsedTime))
            }
          </CountdownCircleTimer>
          <CountdownCircleTimer
            {...timerProps}
            colors={[["#FFFFFF"]]}
            trailColor={[["#00ffd6"]]}
            duration={daySeconds}
            initialRemainingTime={remainingTime % daySeconds}
            onComplete={(totalElapsedTime) => [
              remainingTime - totalElapsedTime > hourSeconds,
            ]}
          >
            {({ elapsedTime }) =>
              renderTime("ժամ", getTimeHours(daySeconds - elapsedTime))
            }
          </CountdownCircleTimer>
          <CountdownCircleTimer
            {...timerProps}
            colors={[["#FFFFFF"]]}
            trailColor={[["#00ffd6"]]}
            duration={hourSeconds}
            initialRemainingTime={remainingTime % hourSeconds}
            onComplete={(totalElapsedTime) => [
              remainingTime - totalElapsedTime > minuteSeconds,
            ]}
          >
            {({ elapsedTime }) =>
              renderTime("րոպե", getTimeMinutes(hourSeconds - elapsedTime))
            }
          </CountdownCircleTimer>
          <CountdownCircleTimer
            {...timerProps}
            colors={[["#FFFFFF"]]}
            trailColor={[["#00ffd6"]]}
            duration={minuteSeconds}
            initialRemainingTime={remainingTime % minuteSeconds}
            onComplete={(totalElapsedTime) => [
              remainingTime - totalElapsedTime > 0,
            ]}
          >
            {({ elapsedTime }) =>
              renderTime("վայրկյան", getTimeSeconds(elapsedTime))
            }
          </CountdownCircleTimer>
        </>
      }
    </div >
  );
}

export default CountDown;
