import React, { forwardRef } from "react";
import { Dialog, Slide, makeStyles } from "@material-ui/core";

import DialogContent from "./DialogContent";
import DialogTitle from "./DialogTitle";
import TermsAndConditions from "./TermsAndConditions";

import './ModalRegisterCoursesOnline.css'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide ref={ref} {...props} />;
});

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",
  },
  paper: {
    borderBottom: "8px solid #123B85 !important"
  }
})

const ModalCoursesOnlineInfo = ({ open, setOpen }) => {

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles()
  return (
    <div>
      <Dialog
        TransitionComponent={Transition}
        keepMounted
        scroll="body"
        transitionDuration={500}
        onClose={handleClose}
        open={open}
        fullWidth
        maxWidth="md"
        classes={{
          scrollPaper: classes.scrollPaper,
          paper: classes.paper
        }}
      >
        <DialogTitle onClose={handleClose} className="modal-online-title">
          <div><h3>Առցանց ուսուցում</h3></div>
        </DialogTitle>
        <DialogContent dividers>
          <TermsAndConditions />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalCoursesOnlineInfo;
