import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types';
const inputStyle = {
    padding: 0,
    margin: 0,
    border: 0,
    background: 'transparent',
}

const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData,
}) => {
    const [value, setValue] = useState(initialValue)

    const onChange = e => {
        setValue(e.target.value)
        updateMyData(index, id, e.target.value)
    }

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    return (
        <input
            style={{
                ...inputStyle, width: (id === "month" ? "10px" : "125px")
            }}
            value={value}
            disabled={id === "month"}
            type={id === "month" ? "text" : "date"}
            onChange={onChange}
        />
    )
}

EditableCell.propTypes = {
    cell: PropTypes.shape({
        value: PropTypes.any.isRequired,
    }),
    row: PropTypes.shape({
        index: PropTypes.number.isRequired,
    }),
    column: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }),
    updateMyData: PropTypes.func.isRequired,
}



export default EditableCell
