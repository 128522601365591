import * as React from "react";
import {
  TopToolbar,
  Edit,
  CloneButton,
  ListButton,
  ShowButton,
  ImageInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
import RichTextInput from 'ra-input-rich-text';

import BlogTitle from "./BlogTitle";
import { PreviewImage } from "../common/components";
import { formatImage } from "../common/formatImage";

const BlogEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <CloneButton className="button-clone" basePath={basePath} record={data} />
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);

const BlogEdit = (props) => (
  <Edit title={<BlogTitle />} actions={<BlogEditActions />} {...props}>
    <SimpleForm>
      <TextInput source="title" validate={required()} />
      <TextInput source="href" validate={required()} />
      <RichTextInput
        source="description"
        fullWidth
        toolbar={{
          container: [
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ size: ["small", false, "large", "huge"] }, { color: [] }],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
              { align: [] }
            ],
            ["link", "image", "video"],
            ["clean"]
          ],
        }}
        validate={required()} />
      <ImageInput
        format={formatImage}
        validate={required()}
        source="picture"
        placeholder="Drop a picture to upload, or click to select it. (maxSize 200 KB)"
        accept="image/png, image/jpg, image/jpeg"
        maxSize={200000}
      >
        <PreviewImage source="url" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

export default BlogEdit;
