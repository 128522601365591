import React from "react";
import { FormLabel } from "@material-ui/core";

import KnowledgeOfLanguages from "./KnowledgeOfLanguages"
import LevelՕfKnowledge from "./LevelՕfKnowledge";
import LevelՕfKnowledgeRadioGroup from "./LevelՕfKnowledgeRadioGroup";

const ProfessionalKnowledgeForm = ({ register, control }) => {
  return (
    <div className="work">
        <div>
        <FormLabel component="legend" required className="level-of-knowledge">
          Մասնագիտական գիտելիքներ CMS
        </FormLabel>
        <table className="table-job-application">
          <tbody>
            <LevelՕfKnowledge />
            <LevelՕfKnowledgeRadioGroup
              {...{
                fildInfo: ["Wordpress", "Joomla", "Magento", "Drupal"],
                RadioGroupName: "professionalKnowledgeOfCMS",
                control,
              }}
            />
          </tbody>
        </table>
      </div>
      <div>
        <FormLabel component="legend" required className="level-of-knowledge">
          Մասնագիտական գիտելիքներ framework
        </FormLabel>
        <table className="table-job-application">
          <tbody>
            <LevelՕfKnowledge />
            <LevelՕfKnowledgeRadioGroup
              {...{
                fildInfo: ["Laravel", "Codelgniter", "Zend", "Yll"],
                control,
                RadioGroupName: "professionalKnowledgeOfFramework",
              }}
            />
          </tbody>
        </table>
      </div>
      <div>
        <FormLabel component="legend" required className="level-of-knowledge">
          Մասնագիտական գիտելիքներ database
        </FormLabel>
        <table className="table-job-application">
          <tbody>
            <LevelՕfKnowledge />
            <LevelՕfKnowledgeRadioGroup
              {...{
                fildInfo: ["MySQL", "PostgreSQL", "MongoDB"],
                control,
                RadioGroupName: "professionalKnowledgeOfDatabase",
              }}
            />
          </tbody>
        </table>
      </div>
      <div>
        <FormLabel component="legend" required className="level-of-knowledge">
          Մասնագիտական գիտելիքներ
        </FormLabel>
        <table className="table-job-application">
        <tbody>
          <LevelՕfKnowledge />
          <LevelՕfKnowledgeRadioGroup
            {...{
              fildInfo: [
                "HTML/CSS",
                "Bootstrap",
                "JavaScript",
                "React/Redux",
                "PHP",
                "Node.js",
                "Python",
                "C#",
                "Java",
              ],
              control,
              RadioGroupName: "professionalKnowledge",
            }}
          />
          </tbody>
        </table>
      </div>
      <KnowledgeOfLanguages {...{ register, control }} />
    </div>
  );
};

export default ProfessionalKnowledgeForm;
