import React from 'react';
import { hydrate, render } from "react-dom";

import reportWebVitals from './reportWebVitals';

import RouteCollector from "./routeCollector/RouteCollector"

import './index.css';

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<RouteCollector/>, rootElement);
} else {
  render(<RouteCollector/>, rootElement);
}

reportWebVitals();

