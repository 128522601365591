import React, { useState,useLayoutEffect } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";
import LazyLoad from 'react-lazyload';
import { FacebookShareButton, FacebookIcon } from "react-share";

import ModalRegisterCourses from "../../modal";
import TeamMemberCard from "../../teamMemberCard"

import "./aboutCourse.css";


const AboutCourse = ({ course, isLoading }) => {

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };


  const { availableIndividualPrice, onlineIndividualPrice, availableGroupPrice, onlineGroupPrice } = course
  const courseInfo = { title: course.title, availableIndividualPrice, onlineIndividualPrice, availableGroupPrice, onlineGroupPrice }
  return (
    <>
      <section className="all-information-about-courses">
        <div className="banner">
          <div>
            <div className="course-title">
              <h2>{isLoading ? <Skeleton width="40%" height={45} /> :
                <>
                  {course.title} <span>{" ծրագրավորում"}</span>
                </>
              }
              </h2>
              {isLoading ?
                <Box >
                  <Skeleton width="90%" height={30} />
                  <Skeleton width="90%" height={30} />
                  <Skeleton width="90%" height={30} />
                </Box>
                : <div className="conditions" dangerouslySetInnerHTML={{ __html: course.condition }} />
              }
              <div className="languages-to-learn">
                <div className="courses-border"></div>
                <h3>
                  Դասավանդվող ծրագ{course.languages.length > 1 ? "րեր" : 'իր'} <span className="ending"></span>
                </h3>
                <div className="image-parent-icon">
                  {isLoading ?
                    Array.from(new Array(3)).map((_, i) => <Box key={i}>
                      <Skeleton width={80} height={140} />
                    </Box>
                    )
                    : course.languages.map((item) => {
                      return (
                        <div key={item._id}>
                          <div className="icons-for-lang">
                            <LazyLoad height={200} offset={100}>
                              <img
                                className="language_icon"
                                src={`${process.env.REACT_APP_BACKEND_PORT}/${item.logo}`}
                                alt={item.language}
                              />
                            </LazyLoad>
                          </div>
                          <h4 className="course-language-name">{item.language}</h4>
                        </div>
                      );
                    })}
                </div>

                <div className="courses-border"></div>
                <div className="term">
                  <div>
                    <h3>Խմբային։</h3>
                    {isLoading ?
                      Array.from(new Array(3)).map((_, i) =>
                        <Skeleton key={i} width="80%" height={30} />
                      )
                      :
                      <>
                        <div className="global">
                          <div className="type-of-courses">
                            <h4 className="term_item">Առկա</h4>
                            <h4 className="term_item">Առցանց</h4>
                            <h4 className="term_item">Տևողություն</h4>
                          </div>
                          <div>
                            <p> <span>{availableGroupPrice}</span> դր․/ամսական</p>
                            <p> <span>{onlineGroupPrice}</span> դր․/ամսական</p>
                            <p>  <span>{course.duration}</span>  ամիս</p>
                          </div>
                        </div>
                        <h4 className="term_item other-h4">Ուսանողների առավելագույն քանակ {course.studentsQuantity}</h4>
                      </>
                    }
                  </div>
                  <div>
                    <h3>Անհատական:</h3>
                    {isLoading ?
                      Array.from(new Array(3)).map((_, i) =>
                        <Skeleton key={i} width="80%" height={30} />
                      )
                      :
                      <div className="global">
                        <div className="type-of-courses">
                          <h4 className="term_item">Առկա</h4>
                          <h4 className="term_item">Առցանց</h4>
                          <h4 className="term_item">Տևողություն</h4>
                        </div>
                        <div>
                          <p> <span>{availableIndividualPrice}</span> դր․/ամսական</p>
                          <p> <span>{onlineIndividualPrice}</span> դր․/ամսական</p>
                          <p>  <span>{course.duration}</span>  ամիս</p>
                        </div>
                      </div>}
                  </div>
                </div>
              </div>
            </div>
            <div className="course_image">
              {
                isLoading || !course.picture ?
                  <Box mb={3} className="general-image" overflow="hidden">
                    <Skeleton variant="rect" width="100%" height="100%" />
                  </Box>
                  :
                  <>
                    <LazyLoad height={200} offset={100}>
                      <img
                        src={`${process.env.REACT_APP_BACKEND_PORT}/${course.picture}`}
                        className="general-image"
                        alt={course.title}
                      />
                    </LazyLoad>
                    <button onClick={handleClickOpen} className="register_course  btn4 btns">
                      Գրանցվել
                    </button>
                  </>
              }
            </div>
          </div>
           <div className="ShareButtonBox" >
            <FacebookShareButton
             url={window.location.href}
             quote={`${course.title} դասընթաց`}
              hashtag={`#${course?.title?.replace(/\s+/g, "").toLowerCase()}դասընթաց`}
              className="ShareButton"
            >   <FacebookIcon size={32} />
              <p className="ShareButtonText">կիսվել</p>
            </FacebookShareButton>
          </div>
          <div className="workers">
            <div className="workers-parent">
              <div>
                <h3>Դասախոս{course.tutorsInfo.length > 1 ? "ներ" : ''}</h3>
                {isLoading ?
                  <Box mb={3}>
                    <Skeleton variant="rect" width={250} height={320} className="tutor-img-loading" />
                  </Box>
                  :
                  <div className="tutor-image">
                    {course.tutorsInfo.map((tutor) => (
                      <TeamMemberCard teamMember={tutor} key={tutor._id} />
                    ))}
                  </div>}
              </div>
              <div className="notes-parent">
                <div className="notes">
                  <h3>Նշումներ</h3>
                  <div>
                    {(isLoading ? Array.from(new Array(6)) : course.notes).map((item, i) => {
                      return (
                        item ? (
                          <div key={item._id}>
                            <LazyLoad height={200} offset={100}>
                              <img src={`${window.location.origin}/images/lamp.svg`} alt={item._id} />
                            </LazyLoad>
                            <p>{item.note}</p>
                          </div>
                        )
                          : <Box width={250} className="notes-loading" key={i}> <Skeleton width="100%" height={30} /></Box>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
        </div>
      </section>
      <ModalRegisterCourses
        {...{
          open, setOpen, courseInfo, Id: "formModalButton_3",
        }}
      />
    </>
  );
};

export default AboutCourse;
