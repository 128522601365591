import React, { useEffect, useState } from 'react';
import { Link } from "react-scroll";

import './arrow.css'

const Arrow = () => {
  const [show, setShow] = useState(false)
  useEffect(() => {
    const arrowScroll = () => {
      if (window.pageYOffset > 1000) {
        setShow(true)
      } else {
        setShow(false)
      }
    }
    window.addEventListener("scroll", arrowScroll, { passive: true })
    return () => {
      window.removeEventListener("scroll", arrowScroll, { passive: true })
    }
  })
  return (
    <Link
      className={`to-top ${show ? "arrow-active" : ""}`}
      to="home"
      spy={true}
      smooth={true}
      delay={0}
      duration={500}
    >
      <img src="images/up.svg" alt="arrow" width="20px" height="20px" />
    </Link >
  )
}



export default React.memo(Arrow);
