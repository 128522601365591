import React, { useState, useEffect } from "react";
import { Loading, Error } from 'react-admin';
import axios from "axios";
import { useHistory } from "react-router-dom";

import AddStudentDialog from "./AddStudentDialog";
import DeleteStudentDialog from "./DeleteStudentDialog"
import ScrapbookEditDialog from "./ScrapbookEditDialog";

import ListItemStudent from "./ListItemStudent"




const ScrapbookList = () => {
  const columns = React.useMemo(
    () => {
      const Headers = []
      for (let i = 0; i <= 12; i++) {
        Headers.push({
          Header: i === 0 ? 'month' : `lesson ${i}`,
          accessor: i === 0 ? 'month' : `lesson${i}`,
        })
      }
      return Headers
    },
    []
  )
  const [scrapbookEditDialog, setScrapbookEditDialog] = useState({ student: null, open: false })
  const [students, setStudents] = useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const history = useHistory()






  useEffect(() => {
    setError("");
    const token = localStorage.getItem("auth")
    axios.get(`${process.env.REACT_APP_BACKEND_PORT}/admin/scrapbooks`, {
      headers: {
        Authorization: token ? `Bearer ${JSON.parse(token)}` : ""
      }
    })
      .then(({ data }) => {
        setStudents(data);
        setLoading(false);
      })
      .catch(error => {
        if (error.response.status === 401) {
          history.replace("/login")
          return
        }
        setError(error);
        setLoading(false);
      })
  }, [history]);



  const addStudentHandler = (student) => {
    setError("");
    setLoading(true);
    const token = localStorage.getItem("auth")
    axios.post(`${process.env.REACT_APP_BACKEND_PORT}/admin/scrapbooks`, { fullName: student },
      {
        headers: {
          Authorization: token ? `Bearer ${JSON.parse(token)}` : ""
        }
      })
      .then(({ data }) => {
        setStudents((prevState) => [...prevState, data]);
        setLoading(false);
      })
      .catch(error => {
        if (error.response.status === 401) {
          history.replace("/login")
          return
        }
        setError(error);
        setLoading(false);
      })
  }

  const deleteStudentDialog = (id) => {
    setError("");
    setLoading(true);
    const token = localStorage.getItem("auth")
    axios.delete(`${process.env.REACT_APP_BACKEND_PORT}/admin/scrapbooks/${id}`, {
      headers: {
        Authorization: token ? `Bearer ${JSON.parse(token)}` : ""
      }
    })
      .then(() => {
        setStudents((prevState) => prevState.filter(e => e.id !== id));
        setLoading(false);
      })
      .catch(error => {
        if (error.response.status === 401) {
          history.replace("/login")
          return
        }
        setError(error);
        setLoading(false);
      })
  }

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  const handleClickOpen = (id) => {
    setScrapbookEditDialog({ student: id, open: true })
  }

  const handleClose = () => {
    setScrapbookEditDialog(false)
  }


  return (
    <div>
      <DeleteStudentDialog {...{ deleteStudentDialog, students }} />
      <AddStudentDialog {...{ addStudentHandler }} />
      <ScrapbookEditDialog
        {...{
          scrapbookEditDialog,
          columns,
          handleClose,
          setError
        }}
      />
      {students.map(e => {
        return (
          <ListItemStudent key={e.id} fullName={e.fullName} password={e.password} handleClickOpen={() => handleClickOpen(e.id)} />
        )
      })}
    </div>
  );
};

export default ScrapbookList;


