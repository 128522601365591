import PeopleIcon from "@material-ui/icons/People";
import UserCreate from "./UserCreate";
import UserEdit from "./UserEdit";
import UserList from "./UserList";
import UserShow from "./UserShow";

const user = {
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
  show: UserShow,
  icon: PeopleIcon,
};

export default user;
