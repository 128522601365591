import React from 'react'
import styled from 'styled-components'
import { useTable } from 'react-table'


const Styles = styled.div`
  padding: 1rem;

  table {
    margin-left: auto;
    margin-right: auto;
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`

function Table({ columns, data }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    })
    return (
        <table {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()} >
                            {row.cells.map(cell => {
                                return <td style={{ 
                                  backgroundColor: cell.row.original.extraԼesson === cell.value && cell.value !== "" ? "#00F3FF" :
                                        cell.value !== "" && typeof cell.value !== "number" ? "#36D463" : ""
                            }}  
                                {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

function StudentTable({ data }) {
    const columns = React.useMemo(
        () => {
            const Headers = []
            for (let i = 0; i <= 12; i++) {
                Headers.push({
                    Header: i === 0 ? 'Ամիս' : `դաս ${i}`,
                    accessor: i === 0 ? 'month' : `lesson${i}`,
                })
            }
            return Headers
        },
        []
    )



    return (
        <Styles>
            <Table columns={columns} data={data} />
        </Styles>
    )
}

export default StudentTable
