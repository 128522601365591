import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  FunctionField,
  TopToolbar,
  CloneButton,
  EditButton,
  ListButton,
} from "react-admin";

import UserTitle from "./UserTitle";

const PreviewImage = ({ record }) => (
  <img
    width={"50%"}
    src={`${process.env.REACT_APP_BACKEND_PORT}/${record.picture}`}
    alt={record.lastName}
  />
);

const UserShowActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <CloneButton className="button-clone" basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const UserShow = (props) => (
  <Show {...props} title={<UserTitle />} actions={<UserShowActions />}>
    <SimpleShowLayout>
      <TextField source="fullName" />
      <TextField source="phone" />
      <TextField source="email" />
      <TextField source="role" />
      <FunctionField
        label="Avatar"
        render={(record) => <PreviewImage record={record} />}
      />
    </SimpleShowLayout>
  </Show>
);

export default UserShow;
