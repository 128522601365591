import React from "react";
import Carousel from "react-material-ui-carousel";
import Skeleton from "@material-ui/lab/Skeleton";
import { Image } from "react-image-and-background-image-fade";

import "./revies.css";

const Revies = ({ revies, isLoading }) => {
  const skeleton = (
    <div className="student">
      <Skeleton variant="circle" className="avatar" />
      <Skeleton width={500} height={25} className="skeleton" />
      <Skeleton width={500} height={25} className="skeleton" />
      <Skeleton width={500} height={25} className="skeleton" />
      <i className="fas fa-quote-left"></i>
      <Skeleton width={200} height={35} className="skeleton" />
    </div>
  );

  return (
    <div className="opinion-parent">

      <h2>
        <span>Կարծիքներ</span> մեր շա՜տ սիրելի ուսանողներից
      </h2>
      <Carousel
        interval={4000}
        navButtonsAlwaysVisible
        changeOnFirstRender={false}
        indicators={false}
        timeout={700}
        animation={"slide"}
        className="student-opinion"
      >
        {isLoading
          ? skeleton
          : revies.map((item) => {
            return (
              <div key={item._id} className="student">
                <Image
                  width={"110px"}
                  height={"110px"}
                  wrapperClassName="avatar"
                  alt={item.fullName}
                  src={`${process.env.REACT_APP_BACKEND_PORT}/${item.picture}`}
                  lazyLoad
                />
                <p className="opinion">{item.review}</p>
                <Image
                  width={"30px"}
                  height={"30px"}
                  src="images/right-quote-sign.svg" alt="quotes" wrapperClassName="revies-quotes"
                  lazyLoad
                />
                <h3 className="user-fullname">{item.fullName}</h3>
              </div>
            );
          })}
      </Carousel>
    </div>
  );
};

export default Revies;
