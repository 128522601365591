import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom"

import ColoredLinearProgress from "../../coloredLinearProgress"

import './blogHeader.css';


const Blogheader = ({ progressing }) => {
  const [isLoadingView, setIsLoadingView] = useState(true)

  useEffect(() => {
    let timerId
    setIsLoadingView(true)
    if (progressing === 100) {
      timerId = setTimeout(() => setIsLoadingView(false), 1500)
    }
    return () => {
      clearTimeout(timerId)
    }
  }, [progressing])
  return (
    <section className="blog-header">
      {isLoadingView ? <ColoredLinearProgress variant="determinate" value={progressing} /> : null}
      <div className="blog-header-parent">
        <Link to="/">
          <img src={`${process.env.REACT_APP_BACKEND_PORT}/logo.webp`} alt="Source-Code-logo" />
        </Link>
        <Link to={{ pathname: "/", state: { scrollToElementName: "blog" } }}>
          <button className="btns btn4">Բլոգ</button>
        </Link>
      </div>
    </section>
  )
}


export default Blogheader;