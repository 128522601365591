import * as React from "react";
import { Children, cloneElement, memo } from "react";
import { useMediaQuery, makeStyles } from "@material-ui/core";
import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  EditButton,
  ShowButton,
  List,
  FunctionField,
  SimpleList,
  TextField,
} from "react-admin";

import { PreviewAvatar } from "../common/components";

const useStyles = makeStyles(() => ({
  field: {
    maxWidth: "15em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

const reviewListActionToolbarStyles = makeStyles({
  toolbar: {
    alignItems: "center",
    display: "flex",
    marginTop: -1,
    marginBottom: -1,
  },
});
const ReviewListActionToolbar = ({ children, ...props }) => {
  const classes = reviewListActionToolbarStyles();
  return (
    <div className={classes.toolbar}>
      {Children.map(children, (button) => cloneElement(button, props))}
    </div>
  );
};

const ReviewBulkActionButtons = memo((props) => (
  <BulkDeleteWithConfirmButton {...props} />
));

const ReviewList = (props) => {
  const classes = useStyles();
  return (
    <List {...props} bulkActionButtons={<ReviewBulkActionButtons />}>
      {useMediaQuery((theme) => theme.breakpoints.down("sm")) ? (
        <SimpleList
          primaryText={(record) => record.fullName}
          secondaryText={(record) => record.review}
          leftAvatar={(record) => PreviewAvatar(record)}
        />
      ) : (
        <Datagrid rowClick="show" optimized>
          <FunctionField
            label="Avatar"
            render={(record) => PreviewAvatar(record)}
          />
          <TextField source="fullName" sortable={false} />
          <TextField
            source="review"
            cellClassName={classes.field}
            sortable={false}
          />
          <ReviewListActionToolbar>
            <EditButton />
            <ShowButton />
          </ReviewListActionToolbar>
        </Datagrid>
      )}
    </List>
  );
};

export default ReviewList;
