import * as React from "react";
import { Avatar } from "@material-ui/core";

const PreviewAvatar = (record) => {
  let src;
  if (typeof record.picture === "string")
    src = `${process.env.REACT_APP_BACKEND_PORT}/${record.picture}`;
  else if (record.picture) src = record.picture.url;
  return <Avatar alt={record.title} src={`${src}`} />;
};

export default PreviewAvatar;
