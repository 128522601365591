import React from "react";
import { withStyles, LinearProgress } from "@material-ui/core"


const ColoredLinearProgress = (props) => {
    const { classes } = props;
    return (
        <LinearProgress
            {...props}
            classes={{
                root: classes.root,
                colorPrimary: classes.colorPrimary,
                barColorPrimary: classes.barColorPrimary
            }}
        />
    );
}

const styles = () => ({
    root: {
        position: "fixed",
        width: "100%",
        zIndex: 99999
    },
    colorPrimary: {
        backgroundColor: "#F1F3F4"
    },
    barColorPrimary: {
        backgroundColor: "#113A84"
    }
});

export default withStyles(styles)(ColoredLinearProgress);
