import * as React from "react";
import {
  ArrayField,
  Datagrid,
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
  FunctionField,
  BooleanField,
  TopToolbar,
  ListButton,
  CloneButton,
  EditButton,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  RichTextField
} from "react-admin";
import { Avatar } from "@material-ui/core";

import ConurseTitle from "./CourseTitle";

const PreviewImage = ({ record }) => (
  <img
    width={"50%"}
    src={`${process.env.REACT_APP_BACKEND_PORT}/${record.picture}`}
    alt={record.lastName}
  />
);

const CourseShowActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <CloneButton className="button-clone" basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const CourseShow = (props) => {
  return (
    <Show {...props} title={<ConurseTitle />} actions={<CourseShowActions />}>
      <SimpleShowLayout>
        <TextField source="title" />
        <TextField source="href" />
        <RichTextField source="condition" />
        <NumberField source="availableIndividualPrice" label="Available individual price" />
        <NumberField source="availableGroupPrice" label="Available group price" />
        <NumberField source="onlineIndividualPrice" label="Online individual price" />
        <NumberField source="onlineGroupPrice" label="Online group price" />
        <NumberField source="studentsQuantity" label="Students quantity" />
        <NumberField source="duration" />
        <NumberField source="startDateCourse" label="Start date course" />
        <BooleanField label="Available" source="available" />
        <BooleanField label="Display on slides" source="displayՕnSlides" />
        <ReferenceArrayField label="tutors" source="tutors" reference="admin/users"         >
          <SingleFieldList>
            <ChipField source="fullName" size="small" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField
          label="Information about tutor"
          source="tutorsInfo"
          reference="admin/teams"
          sortable={false}
        >
          <SingleFieldList>
            <ChipField source="fullName" size="small" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ArrayField source="languages" fieldKey="_id">
          <Datagrid>
            <TextField source="language" sortable={false} />
            <FunctionField
              label="Logo"
              render={(record) => (
                <Avatar
                  alt={record.language}
                  src={`${process.env.REACT_APP_BACKEND_PORT}/${record.logo}`}
                />
              )}
            />
          </Datagrid>
        </ArrayField>
        <ArrayField source="notes" fieldKey="_id">
          <Datagrid>
            <TextField source="note" sortable={false} />
          </Datagrid>
        </ArrayField>
        <FunctionField
          label="picture"
          render={(record) => <PreviewImage record={record} />}
        />
      </SimpleShowLayout>
    </Show>
  );
};

export default CourseShow;
