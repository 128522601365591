import React, { useState } from "react";
import { Link } from "react-scroll";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Skeleton from "@material-ui/lab/Skeleton";

import "./FAQ.css";


const FAQ = ({ FAQs, isLoading }) => {
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const answerLeft = [];
  const answerRight = [];

  (isLoading ? Array.from(new Array(8)) : FAQs).forEach((item, i) => {
    const element = (
      <Accordion
        key={item ? item._id : i}
        expanded={expanded === `panel${i}`}
        onChange={handleChange(`panel${i}`)}
      >
        <AccordionSummary
          className={expanded === `panel${i}` ? "question-panel-active" : ""}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography className="heading">
            {item ? item.question : <Skeleton variant="text" width={"100%"} height={30} />}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {
              item ? item.answer :
                <>
                  <Skeleton variant="text" width={"100%"} height={25} />
                  <Skeleton variant="text" width={"100%"} height={25} />
                  <Skeleton variant="text" width={"100%"} height={25} />
                </>
            }
          </Typography>
        </AccordionDetails>
      </Accordion>
    );
    if (i % 2 === 0) answerLeft.push(element);
    else answerRight.push(element);
  });
  return (
    <div className="root">
      <div className="questions">
        <h1>
          <span>Հ</span>աճախ <span>Տ</span>րվող <span>Հ</span>արցեր
        </h1>
        <div className="answer-parent">
          <div className="answer-left">
            {answerLeft}
          </div>
          <div className="answer-right">
            {answerRight}
          </div>
        </div>
        <h2 className="other-question">
          Այլ հարցերի կամ առաջարկների դեպքում
          <Link
            activeClass="active"
            to="contact_us"
            spy={true}
            smooth={true}
            duration={100}
            offset={-100}
          >
            գրեք մեզ այստեղ
          </Link>
        </h2>
      </div>
    </div>
  );
}

export default FAQ
