import * as React from "react";
import {
  Create,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  ImageInput,
  ListButton,
  TopToolbar,
} from "react-admin";
import RichTextInput from 'ra-input-rich-text';

import { PreviewImage } from "../common/components";
import { formatImage } from "../common/formatImage";

const BlogCreateToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="save and show" redirect="show" submitOnEnter={true} />
    <SaveButton
      label="save and add"
      redirect={false}
      submitOnEnter={false}
      variant="text"
    />
  </Toolbar>
);

const BlogCreateActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

const BlogCreate = (props) => (
  <Create {...props} actions={<BlogCreateActions />}>
    <SimpleForm toolbar={<BlogCreateToolbar />}>
      <TextInput source="title" validate={required()} />
      <RichTextInput
        source="description"
        fullWidth
        toolbar={{
          container: [
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ size: ["small", false, "large", "huge"] }, { color: [] }],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
              { align: [] }
            ],
            ["link", "image", "video"],
            ["clean"]
          ],
        }}
        validate={required()} />
      <ImageInput
        format={formatImage}
        validate={required()}
        source="picture"
        placeholder="Drop a picture to upload, or click to select it. (maxSize 200 KB)"
        accept="image/png, image/jpg, image/jpeg"
        maxSize={200000}
      >
        <PreviewImage source="url" />
      </ImageInput>
    </SimpleForm>
  </Create>
);

export default BlogCreate;
