import React, { useState } from "react";

import "./page404.css";
import ErrorPageHeader from "./errorPageHeader"

const Page404 = () => {
  const [position, setPosition] = useState({ top: "400px", left: "200px" });
  const handleCursor = (event) => {
    setPosition({ top: `${event.pageY}px`, left: `${event.pageX}px` });
  };
  return (
    <>
     <ErrorPageHeader/>
      <div className="pageError404" onMouseMove={handleCursor}>
        <div className="text-for-error">
          <h1>404</h1>
          <h3>
            Ներեցեք, մենք չենք կարող գտնել այն, ինչ փնտրում եք, քանի որ այստեղ շատ մութ է...
        </h3>
        </div>
        <div
          className="torch"
          style={{ top: position.top, left: position.left }}
        ></div>
      </div>
    </>
  );
};

export default Page404;
