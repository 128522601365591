import SettingsIcon from "@material-ui/icons/Settings";
import SettingCreate from "./SettingCreate";
import SettingEdit from "./SettingEdit";
import SettingList from "./SettingList";
import SettingShow from "./SettingShow";

const setting = {
  list: SettingList,
  create: SettingCreate,
  edit: SettingEdit,
  show: SettingShow,
  icon: SettingsIcon,
};

export default setting;
