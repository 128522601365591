import * as React from "react";
import { Children, cloneElement, memo } from "react";
import { useMediaQuery, makeStyles } from "@material-ui/core";
import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  EditButton,
  ShowButton,
  List,
  FunctionField,
  SimpleList,
  TextField,
  DateField,
  RichTextField
} from "react-admin";

import { PreviewAvatar } from "../common/components";

const blogListActionToolbarStyles = makeStyles({
  toolbar: {
    alignItems: "center",
    display: "flex",
    marginTop: -1,
    marginBottom: -1,
  },
});

const BlogListActionToolbar = ({ children, ...props }) => {
  const classes = blogListActionToolbarStyles();
  return (
    <div className={classes.toolbar}>
      {Children.map(children, (button) => cloneElement(button, props))}
    </div>
  );
};

const BlogBulkActionButtons = memo((props) => (
  <BulkDeleteWithConfirmButton {...props} />
));



const useStyles = makeStyles(() => ({
  field: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }
}));

const BlogList = (props) => {
  const classes = useStyles();
  return (
    <List {...props} bulkActionButtons={<BlogBulkActionButtons />}>
      {useMediaQuery((theme) => theme.breakpoints.down("md")) ? (
        <SimpleList
          primaryText={(record) => `Title: ${record.title}`}
          leftAvatar={(record) => PreviewAvatar(record)}
        />
      ) : (
          <Datagrid rowClick="show" optimized>
            <FunctionField
              label="Avatar"
              render={(record) => PreviewAvatar(record)}
            />
            <TextField source="title" sortable={false} />
            <RichTextField source="description" stripTags={true} cellClassName={classes.field} />
            <DateField source="publication_date" />
            <BlogListActionToolbar>
              <EditButton />
              <ShowButton />
            </BlogListActionToolbar>
          </Datagrid>
        )}
    </List>
  );
};

export default BlogList;
