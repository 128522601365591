import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  FunctionField,
  TopToolbar,
  ListButton,
  CloneButton,
  EditButton,
  DateField,
  RichTextField
} from "react-admin";

import BlogTitle from "./BlogTitle";

const PreviewImage = ({ record }) => (
  <img
    width={"50%"}
    src={`${process.env.REACT_APP_BACKEND_PORT}/${record.picture}`}
    alt={record.lastName}
  />
);

const BlogShowActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <CloneButton className="button-clone" basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const BlogShow = (props) => {
  return (
    <Show {...props} title={<BlogTitle />} actions={<BlogShowActions />}>
      <SimpleShowLayout>
        <TextField source="title" />
        <TextField source="href" />
        <RichTextField source="description" />
        <DateField source="publication_date" />
        <FunctionField
          label="picture"
          render={(record) => <PreviewImage record={record} />}
        />
      </SimpleShowLayout>
    </Show>
  );
};

export default BlogShow;
