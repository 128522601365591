import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";


import "./blog.css";

const BoxVariants = {
  visible: { opacity: 1, transition: { duration: 1.5 } },
  hidden: { opacity: 0, }
};

const textVariants = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: -100 }
};

const Blog = ({ blogs, isLoading }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const skeleton = Array.from(new Array(3)).map((_, i) => (
    <div key={i} className="first-article cardul">
      <Skeleton variant="rect" width={"100%"} height={"100%"} className="blog-loading" />
    </div>
  ));

  return (
    <main className={inView ? "blog  blog_background_image" : "blog"}  >
      <div className="big-blog" >
        <h5>Թարմացվող նյութեր</h5>
        <motion.h2
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={textVariants}>
          Վերջին նորություններ և հոդվածներ
        </motion.h2>
        <motion.div
          className="blog-parent"
          animate={controls}
          initial="hidden"
          variants={BoxVariants}>
          {isLoading ? skeleton
            : blogs.map((item) => (
              <div key={item._id} className="first-article cardul">
                <img
                  src={inView ? `${process.env.REACT_APP_BACKEND_PORT}/${item.picture}` : "#"}
                  alt={item.title}
                />
                <h2>{item.title}</h2>
                <div className="development-news info">
                  <Link
                    to={`/blogs/${item.href}`}
                    className="btns btn4"
                  >
                    Մանրամասներ
                  </Link>
                </div>
              </div>
            ))}
        </motion.div>
      </div>
    </main>
  );
};

export default Blog;
