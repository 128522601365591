const authProvider = {
  login: ({ username, password }) => {
    const request = new Request(
      `${process.env.REACT_APP_BACKEND_PORT}/admin/login`,
      {
        method: "POST",
        body: JSON.stringify({ username, password }),
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    );
    return fetch(request)
      .then((response) => {
        if (response.status === 401) {
          throw new Error("Login failed! Check authentication credentials");
        }
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((auth) => {
        localStorage.setItem("auth", JSON.stringify(auth.accessToken));
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },

  checkError: (error) => {
    const status = error.status;
    if (status === 401) {
      localStorage.removeItem("auth");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem("auth") ? Promise.resolve() : Promise.reject(),

  logout: () => {
    localStorage.removeItem("auth");
    return Promise.resolve();
  },
  getPermissions: () => Promise.resolve(),
};

export default authProvider;
