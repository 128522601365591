import React from 'react';
import CountUp from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';
import { BackgroundImage } from 'react-image-and-background-image-fade'

import './counter.css'

const Counter = () => {
  return (
    <BackgroundImage
      element="section"
      src="/images/bg-9.jpg"
      className="py-100 counter-section-2 bg9"
      lazyLoad
    >
      <div className="counterP">
        <div className="row5">
          <div className="counterParent">
            <div className="col-md-6 col-lg-3">
              <div className="counter-box-2">
                <div className="icon">
                  <img src="images/briefcase.svg" alt="briefcase" loading="lazy" />
                </div>
                <h3 className="counter">
                  <CountUp end={5} redraw={true} suffix={"+"}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </h3>
                <p className="counter-title">Տարի բիզնեսում</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="counter-box-2">
                <div className="icon">
                  <img src="images/code-interface.svg" alt="code" loading="lazy" />
                </div>
                <h3 className="counter">
                  <CountUp end={100000} redraw={true} suffix={"+"}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>

                </h3>
                <p className="counter-title">Գրված կոդեր</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="counter-box-2">
                <div className="icon">
                  <img src="images/student-with-graduation-cap.svg" alt="graduation" loading="lazy" />
                </div>
                <h3 className="counter">
                  <CountUp end={2051} redraw={true}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </h3>
                <p className="counter-title">Ուրախ ուսանողներ</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="counter-box-2">
                <div className="icon">
                  <img src="images/business-partnership.svg" alt="partnership" loading="lazy" />
                </div>
                <h3 className="counter">
                  <CountUp end={48} redraw={true}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </h3>
                <p className="counter-title">Աշխատանքի ընդունված ուսանող</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="counter-box-2">
                <div className="icon">
                  <img src="images/student-sleeping.svg" alt="sleeping" loading="lazy" />
                </div>
                <h3 className="counter">
                  <CountUp end={0} redraw={true}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </h3>
                <p className="counter-title">Ձանձրացած ուսանողներ</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BackgroundImage>
  );
};
export default React.memo(Counter);