import * as React from "react";

const SettingTitle = ({ record }) => {
  let title = "";
  if (record.category === "setting") {
    title = record.settingName;
  } else if (record.category === "logo") title = "logo";
  else title = "banner";
  return <span>{`Setting - ${title}`}</span>;
};

export default SettingTitle;
