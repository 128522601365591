import simpleRestProvider from "ra-data-simple-rest";
import { fetchUtils } from "react-admin";

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: "application/json" });
    }
    options.headers.set(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("auth"))}`
    );
    return fetchUtils.fetchJson(url, options);
};

const simpleRestDataProvider = simpleRestProvider(
    `${process.env.REACT_APP_BACKEND_PORT}`,
    httpClient
);

export default simpleRestDataProvider