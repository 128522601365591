import React, { useEffect, useState } from "react";
import axios from "axios";
import { Loading } from 'react-admin';
import { useHistory } from "react-router-dom";



import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from "@material-ui/core/DialogContentText"
import { IconButton } from '@material-ui/core'
import CloseIcon from "@material-ui/icons/Close"


import ScrapbookTable from "./ScrapbookTable"

const TableStudentDialog = props => {
    const [student, setStudent] = useState(null)
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const { handleClose, scrapbookEditDialog, columns, setError } = props

    const updateMyData = (id) => (rowIndex, columnId, value) => {
        setError("");
        const token = localStorage.getItem("auth")
        axios.put(`${process.env.REACT_APP_BACKEND_PORT}/admin/scrapbooks/${id}`, { rowIndex, columnId, value }, {
            headers: {
                Authorization: token ? `Bearer ${JSON.parse(token)}` : ""
            }
        })
            .then(({ data }) => {
                setStudent(data)

            })
            .catch(error => {
                if (error.response.status === 401) {
                    history.replace("/login")
                    return
                }
                setError(error)
            })
    }

    useEffect(() => {
        setError("");
        setLoading(true)
        if (!scrapbookEditDialog.student) {
            return
        }
        const token = localStorage.getItem("auth")
        axios.get(`${process.env.REACT_APP_BACKEND_PORT}/admin/scrapbooks/${scrapbookEditDialog.student}`, {
            headers: {
                Authorization: token ? `Bearer ${JSON.parse(token)}` : ""
            }
        })
            .then(({ data }) => {
                setStudent(data)
                setLoading(false)
            })
            .catch(error => {
                if (error.response.status === 401) {
                    history.replace("/login")
                    return
                }
                setError(error)
            })
    }, [scrapbookEditDialog, setError, history]);
    if (!student) return null


    const handleCloseDialog = () => {
        handleClose()
        setStudent(null)

    }
    return (
        <div >
            <Dialog
                maxWidth="xxl"
                open={true}
                onClose={handleCloseDialog}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle disableTypography id="form-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                    <p style={{ marginLeft: "20px" }}>{student.fullName}</p>
                    <IconButton onClick={handleCloseDialog}>
                        <CloseIcon />
                    </IconButton> </DialogTitle>
                <DialogContent  >
                    <DialogContentText style={{ textAlign: "center", color: "#000", fontWeight: "bold" }}>Հաճախումների մատյան</DialogContentText>
                    {loading ?
                        <Loading />
                        :
                        < ScrapbookTable {...{ columns, data: student.scrapbooks, updateMyData: updateMyData(student.id) }} />
                    }

                </DialogContent>
            </Dialog>
        </div >
    )
}



export default TableStudentDialog
