import React from "react";
import { Controller } from "react-hook-form";
import { RadioGroup, FormControlLabel, FormControl } from "@material-ui/core";
import isMobilePhone from "validator/lib/isMobilePhone";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

import StyledRadio from "../jobApplicationForm/StyledRadio";

const CssTextField = withStyles({
  root: {
    marginBottom: "25px",
    "& input": {
      color: "#000",
    },
    "& textarea": {
      color: "#000",
    },
  },
})(TextField);

const FegisterFormForCourses = ({
  handleOnSubmit,
  register,
  handleSubmit,
  errors,
  values,
  control,
  Id,
}) => {
  return (
    <>
      <form
        noValidate
        id={Id}
        autoComplete="off"
        onSubmit={handleSubmit(handleOnSubmit)}
      >
        <div>
          <CssTextField
            label="Անուն-ազգանուն"
            name="fullName"
            fullWidth
            required
            inputRef={register({ required: true })}
            error={errors.fullName ? true : false}
            helperText={errors.fullName ? "Պարտադին լրացման դաշտ" : ""}
            variant="outlined"
          />
        </div>
        <div>
          <CssTextField
            label="Բջջ․ հեռախոսահամար"
            fullWidth
            name="phone"
            placeholder="+374XXXXXXXX"
            inputRef={register({
              required: true,
              validate: (value) =>
                isMobilePhone(value, "am-AM") || "invalidPhone",
            })}
            required
            error={errors.phone ? true : false}
            helperText={
              errors.phone &&
              (errors.phone.type === "required"
                ? "Պարտադին լրացման դաշտ"
                : "Սխալ հեռախոսահամար")
            }
            variant="outlined"
          />
        </div>
        <div>
          <CssTextField
            label="Հավելյալ նշումներ և հարցեր"
            name="message"
            inputRef={register({
              validate: (value) => !(value.length > 1000),
            })}
            error={errors.message ? true : false}
            helperText={errors.message && "Առավելագույն սիմվոլների քանակը 1000"}
            rows={4}
            fullWidth
            variant="outlined"
            multiline
          />
        </div>
        <p className="lesson-type-title">
          Ցանկանում եմ դիմել՝
      </p>
        <Controller
          as={
            <FormControl
              component="fieldset"
            >
              <RadioGroup name={"typeCourse"} value={values.typeCourse}>
                {["Առկա", "Առցանց"].map((item) => (
                  <FormControlLabel
                    key={item}
                    value={item}
                    control={<StyledRadio />}
                    label={item}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          }
          name="typeCourse"
          control={control}
        />
        <Controller
          as={
            <FormControl
              component="fieldset"
            >
              <RadioGroup name="studentsNumber" value={values.studentsNumber}>
                {["Խմբային", "Անհատական"].map((item) => (
                  <FormControlLabel
                    key={item}
                    value={item}
                    control={<StyledRadio />}
                    label={item}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          }
          name="studentsNumber"
          control={control}
        />
      </form>
    </>
  );
};

export default FegisterFormForCourses;
