import React from "react";
import { makeStyles, IconButton, FormLabel } from "@material-ui/core";

import PhotoCamera from "@material-ui/icons/PhotoCamera";

const useStyles = makeStyles(() => ({
  inputFile: {
    display: "none",
  },
}));

const PictureInput = ({ register, watch, errors }) => {
  const picture = watch("picture");
  const classes = useStyles();
  return (
    <>
      <FormLabel component="legend">Լուսանկար</FormLabel>
      <input
        name="picture"
        ref={register({
          validate: {
            checkSize: (value) =>
              (value && value[0] instanceof File
                ? value[0].size < 1000000
                : true),
            checkType: (value) =>
            (value && value[0] instanceof File
              ? ["image/png", "image/jpg", "image/jpeg"].includes(
                value[0].type
              )
              : true),
          },
        })}
        accept="image/png, image/jpg, image/jpeg"
        className={classes.inputFile}
        id="icon-button-file"
        type="file"
      />
      <label htmlFor="icon-button-file">
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
        >
          <PhotoCamera />
        </IconButton>
      </label>
      {picture && picture[0] instanceof File ? <p className="picture-name">{picture[0].name}</p> : null}
      {errors.picture ? (
        <span className="error-for-files">
          {errors.picture.type === "checkSize"
            ? "Պատկերի չափը չպետք է գերազանցի 1 մեգաբայթը"
            : "Պատկերը պետք է լինի հետևյալ ձևաչափով (․png, .jpg, .jpeg)"}
        </span>
      ) : null}
    </>
  );
};

export default PictureInput;
