import React, { useEffect, useState } from "react";
import ColoredLinearProgress from "../coloredLinearProgress"

import SidebarNav from "./sidebarNav";

const Menu = ({ settings, isMenuOpen, setIsMenuOpen, progressing }) => {
  const [isLoadingView, setIsLoadingView] = useState(true)

  useEffect(() => {
    let timerId
    setIsLoadingView(true)
    if (progressing === 100) {
      timerId = setTimeout(() => setIsLoadingView(false), 1500)
    }
    return () => {
      clearTimeout(timerId)
    }
  }, [progressing])

  return (
      <div id="sidebar-nav" className="sidebar-open-nav sidebar-bg" >
        {isLoadingView ? <ColoredLinearProgress variant="determinate" value={progressing} /> : null}
        <SidebarNav {...{ isMenuOpen, settings, setIsMenuOpen }} />
      </div>
  );
};

export default Menu;
