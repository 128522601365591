import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DeleteIcon from '@material-ui/icons/Delete'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'


const DeleteStudentDialog = props => {
    const { students, deleteStudentDialog } = props
    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleDelete = (id) => {
        deleteStudentDialog(id)
        handleClose()
    }

    return (
        <div>
            <Tooltip title="Add">
                <IconButton aria-label="add" onClick={handleClickOpen}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
            Student
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth="md"
            >
                <DialogTitle id="form-dialog-title">Delete Student</DialogTitle>
                <DialogContent >
                    {students.map(e => {
                        return <div key={e.id} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <span>{e.fullName}</span>
                            <Button onClick={() => handleDelete(e.id)} color="primary">
                                Delete
                            </Button>
                        </div>
                    })}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

DeleteStudentDialog.propTypes = {
    deleteStudentDialog: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
}

export default DeleteStudentDialog
