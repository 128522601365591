import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { makeStyles, Button } from "@material-ui/core";
import { Link } from "react-router-dom"
import CircularProgress from '@material-ui/core/CircularProgress';

import WorkingDataForm from "./WorkingDataForm";
import PersonalInformationForm from "./PersonalInformationForm";
import ProfessionalKnowledgeForm from "./ProfessionalKnowledgeForm";
import OtherDataForm from "./OtherDataForm";
import AlertDialog from "../modal/AlertDialog"




import "./JobApplicationForm.css";

const defaultValues = {
  fullName: "",
  age: "",
  gender: "",
  maritalStatus: "",
  phone: "",
  email: "",
  residenceAddress: "",
  minimumSalary: "",
  teachStudents: "",
  education: "",
  workExperience: "",
  ownProjects: "",
  otherInformation: "",
  otherLanguages: "",
  knowledgeOfLanguages: ["", "", "", "",],
  professionalKnowledgeOfCMS: {
    Wordpress: "չգիտեմ",
    Joomla: "չգիտեմ",
    Magento: "չգիտեմ",
    Drupal: "չգիտեմ",
  },
  professionalKnowledgeOfFramework: {
    Laravel: "չգիտեմ",
    Codelgniter: "չգիտեմ",
    Zend: "չգիտեմ",
    Yll: "չգիտեմ",
  },
  professionalKnowledgeOfDatabase: {
    MySQL: "չգիտեմ",
    PostgreSQL: "չգիտեմ",
    MongoDB: "չգիտեմ",
  },
  professionalKnowledge: {
    "HTML/CSS": "չգիտեմ",
    Bootstrap: "չգիտեմ",
    JavaScript: "չգիտեմ",
    "React/Redux": "չգիտեմ",
    PHP: "չգիտեմ",
    Nodejs: "չգիտեմ",
    Python: "չգիտեմ",
    "C#": "չգիտեմ",
    Java: "չգիտեմ",
  },
};


const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
      width: "40ch",
    },
  },
  buttonProgress: {
    color: "blue",
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const JobApplicationForm = () => {
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageRespons, setMessageRespons] = useState("");
  const { handleSubmit, register, watch, errors, control, reset } = useForm({
    defaultValues,
    mode: "onBlur",
  });

  const handleClickOpen = () => {
    reset(defaultValues);
    setOpenAlertDialog(true);
  };

  const handleOnSubmit = async (data) => {
    setLoading(true)
    setMessageRespons("Ձեր հայտն ուղարկված է")

    try {
      if (data.picture.length === 0 && data.cvFile.length === 0) {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_PORT}/jobApplication`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );
        if (response.status < 200 || response.status >= 300) {
          throw new Error();
        }
        handleClickOpen()
        setLoading(false)
        return;
      }

      const files = new FormData();

      if (data.picture[0] instanceof File)
        files.append("picture", data.picture[0]);

      if (data.cvFile[0] instanceof File)
        files.append("cvFile", data.cvFile[0]);

      const requestFileUpload = new Request(
        `${process.env.REACT_APP_BACKEND_PORT}/fileUpload`,
        { method: "POST", body: files }
      );
      const resFileUpload = await fetch(requestFileUpload);
      if (resFileUpload.status < 200 || resFileUpload.status >= 300) {
        throw new Error();
      }
      const result = await resFileUpload.json();

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_PORT}/jobApplication`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...data, ...result }),
        }
      );
      if (response.status < 200 || response.status >= 300) {
        throw new Error();
      }
    } catch (error) {

      setMessageRespons("Ձեր հայտը չի ուղարկվել,փորձեք մի փոքր ուշ")
    }
    handleClickOpen()
    setLoading(false)
  };
  const classes = useStyles();

  return (
    <div className="job-application-parent">
      <div className="icon-for-job">
        <div className="img">
          <Link to="/">
            <img src={`${process.env.REACT_APP_BACKEND_PORT}/logo.webp`}
              alt="Source-Code-logo" />
          </Link>
          <div>
            <p>
              Սորս կոդ ՍՊ ընկերությունը վեբ և մոբայլ ծրագրավորման
              ծառայություններ և ծրագրավորման հիմնարար դասընթացներ մատուցող
              կազմակերպություն է։ Մենք համագործակցում ենք ինչպես Հայաստանյան,
              այնպես էլ Հայաստանի սահմաններից դուրս գտնվող կազմակերպությունների
              և անհատ ձեռնարկատերերի հետ, որոնք զբաղվում են տեղեկատվական
              տեխնոլոգիաների մշակմամբ և զարգացմամբ։
            </p>
            <p>
              Մենք միշտ բաց ենք և պատրաստ, համալրելու մեր թիմը ակտիվ,
              պրոֆեսիոնալ և ճկուն մտածողությամբ աշխատակիցներով։
            </p>
          </div>
        </div>
        <div className="job-border">
          <p>
            Լրացրեք ստորև ներկայացված հայտադիմումը, մենք կապ կհաստատենք Ձեզ հետ։
          </p>
        </div>
      </div>

      <form
        className={`${classes.root} work-form`}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(handleOnSubmit)}
      >
        <PersonalInformationForm {...{ register, errors, control, watch }} />
        <WorkingDataForm {...{ register, errors }} />
        <ProfessionalKnowledgeForm {...{ register, control }} />
        <OtherDataForm {...{ register, errors, watch }} />
        <div className="send-info-button">
          <Button disabled={loading} type="submit" variant="contained" color="primary">
            Ուղարկել
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>

        </div>
      </form>
      <AlertDialog
        {...{ openAlertDialog, setOpenAlertDialog, messageRespons }}
      />
    </div>
  );
};

export default JobApplicationForm;
