import React from "react";
import Typewriter from "typewriter-effect";
import { BackgroundImage } from 'react-image-and-background-image-fade'

import "./banner.css";

const Banner = ({ settings }) => {
  let bannerTexts = []
  let bannerPicture = null
  settings.forEach(element => {
    if (element.bannerPicture) bannerPicture = element.bannerPicture
    if (element.bannerTexts) bannerTexts = element.bannerTexts.map(e => e.bannerText)
  });

  return (
    <BackgroundImage
      id="section-1"
      className="slider"
      src={bannerPicture ? `${process.env.REACT_APP_BACKEND_PORT}/${bannerPicture}` : "#"}
      style={{
        background: `no-repeat 50% 27%/cover`,
      }}
    >
      <div>
        <div>
          <h2 className="subtitle">
            <Typewriter
              options={{
                strings: bannerTexts,
                autoStart: true,
                loop: true,
                delay: 70,
              }}
            />
          </h2>
        </div>
      </div>
    </BackgroundImage>
  );
};

export default React.memo(Banner);
