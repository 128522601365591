import * as React from "react";
import { Children, cloneElement, memo } from "react";
import { useMediaQuery, makeStyles } from "@material-ui/core";
import lodashGet from "lodash/get";
import jsonExport from "jsonexport/dist";
import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  downloadCSV,
  EditButton,
  List,
  ShowButton,
  SimpleList,
  TextField,
  FunctionField,
} from "react-admin";

const exporter = (Setting) => {
  const data = Setting.map((Setting) => ({
    ...Setting,
    backlinks: lodashGet(Setting, "backlinks", []).map(
      (backlink) => backlink.url
    ),
  }));
  jsonExport(data, (err, csv) => downloadCSV(csv, "Setting"));
};

const SettingListBulkActions = memo((props) => (
  <BulkDeleteWithConfirmButton {...props} />
));

const useSettingListActionToolbarStyles = makeStyles({
  toolbar: {
    alignItems: "center",
    display: "flex",
    marginTop: -1,
    marginBottom: -1,
  },
});

const SettingListActionToolbar = ({ children, ...props }) => {
  const classes = useSettingListActionToolbarStyles();
  return (
    <div className={classes.toolbar}>
      {Children.map(children, (button) => cloneElement(button, props))}
    </div>
  );
};

const PreviewImageSetting = ({ record }) => {
  const { category, bannerPicture } = record
  let src = `${process.env.REACT_APP_BACKEND_PORT}/logo.webp`
  if (category === "banner") {
    src = typeof bannerPicture === "object" ? bannerPicture.url : `${process.env.REACT_APP_BACKEND_PORT}/${bannerPicture}`
  }
  return (
    <div>
      <img
        width="25%"
        height="25%"
        src={src}
        alt={category}
      />
    </div>
  );
};

const PreviewImageIcon = ({ record }) => {
  const { logoIcon } = record
  let src = ""
  if (typeof logoIcon === "object") {
    src = logoIcon.url
  } else src = `${process.env.REACT_APP_BACKEND_PORT}/${logoIcon}`
  return (
    <img width="20%" alt="icon" src={src} />
  );
};

const optionValueField = (record) => {
  let form = null;
  if (record.category === "setting") {
    form = <TextField record={record} source="value" />;
  } else if (record.category === "logo") {
    form = (
      <>
        <h3>Logo</h3>
        <FunctionField
          record={record}
          render={(record) => <PreviewImageSetting record={record} />}
        />
        <h3>Icon</h3>
        <FunctionField
          record={record}
          render={(record) => <PreviewImageIcon record={record} />}
        />
      </>
    );
  } else if (record.category === "banner") {
    form = (
      <>
        <FunctionField
          record={record}
          render={(record) => <PreviewImageSetting record={record} />}
        />
        <h3>Banner texts</h3>
        <ul>
          {record.bannerTexts.map((e) => (
            <li key={e._id}>{e.bannerText}</li>
          ))}
        </ul>
      </>
    );
  }

  return <>{form}</>;
};

const optionNameField = (record) => {
  let form = null;
  if (record.category === "setting") {
    form = <TextField record={record} source="settingName" />;
  } else if (record.category === "logo") {
    form = <TextField record={record} source="logoName" />;
  } else if (record.category === "banner") {
    form = <TextField record={record} source="bannerName" />;
  }
  return <>{form}</>;
};

const SettingList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <List
      {...props}
      bulkActionButtons={<SettingListBulkActions />}
      exporter={exporter}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => optionNameField(record)}
          secondaryText={(record) => optionValueField(record)}
        />
      ) : (
          <Datagrid rowClick="show" optimized>
            <FunctionField
              label="Option Name"
              sortable={false}
              render={(record) => optionNameField(record)}
            />
            <FunctionField
              label="Option value"
              sortable={false}
              render={(record) => optionValueField(record)}
            />
            <SettingListActionToolbar>
              <EditButton />
              <ShowButton />
            </SettingListActionToolbar>
          </Datagrid>
        )}
    </List>
  );
};

export default SettingList;
