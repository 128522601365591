import { SupervisedUserCircleRounded } from "@material-ui/icons";
import TeamCreate from "./TeamCreate";
import TeamEdit from "./TeamEdit";
import TeamList from "./TeamList";
import TeamShow from "./TeamShow";

const team = {
  list: TeamList,
  create: TeamCreate,
  edit: TeamEdit,
  show: TeamShow,
  icon: SupervisedUserCircleRounded,
};

export default team;
