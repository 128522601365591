import * as React from "react";
import {
  Show,
  TextField,
  FunctionField,
  ListButton,
  CloneButton,
  EditButton,
  TopToolbar,
  SimpleShowLayout,
} from "react-admin";

import ReviewTitle from "./ReviewTitle";

const PreviewImage = ({ record }) => (
  <img
    width={"50%"}
    src={`${process.env.REACT_APP_BACKEND_PORT}/${record.picture}`}
    alt={record.fullName}
  />
);

const ReviewShowActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <CloneButton className="button-clone" basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const ReviewShow = (props) => (
  <Show {...props} title={<ReviewTitle />} actions={<ReviewShowActions />}>
    <SimpleShowLayout>
      <TextField source="fullName" />
      <TextField source="review" />
      <FunctionField
        label="picture"
        render={(record) => <PreviewImage record={record} />}
      />
    </SimpleShowLayout>
  </Show>
);

export default ReviewShow;
