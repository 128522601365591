import * as React from "react";
import {
  Create,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  ImageInput,
  TopToolbar,
  ListButton,
  email,
} from "react-admin";

import { PreviewImage } from "../common/components";
import { formatImage } from "../common/formatImage";

const UserCreateToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="save and show" redirect="show" submitOnEnter={true} />
    <SaveButton
      label="save and add"
      redirect={false}
      submitOnEnter={false}
      variant="text"
    />
  </Toolbar>
);

const UserCreateActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

const UserCreate = (props) => (
  <Create {...props} actions={<UserCreateActions />}>
    <SimpleForm toolbar={<UserCreateToolbar />}>
      <TextInput source="fullName" validate={required()} />
      <TextInput source="email" validate={[required(), email()]} />
      <TextInput source="phone" validate={required()} />
      <SelectInput
        source="role"
        validate={required()}
        choices={[
          { id: "user", name: "User" },
          { id: "teacher", name: "Teacher" },
        ]}
      />
      <ImageInput
        format={formatImage}
        validate={required()}
        source="picture"
        placeholder="Drop a picture to upload, or click to select it. (maxSize 500 KB)"
        accept="image/png, image/jpg, image/jpeg"
        maxSize={500000}
      >
        <PreviewImage source="url" />
      </ImageInput>
    </SimpleForm>
  </Create>
);

export default UserCreate;
