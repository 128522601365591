import React from "react";
import { TextField, FormLabel } from "@material-ui/core";

const WorkingDataForm = ({ register, errors }) => {
  return (
    <>
      <div>
        <FormLabel component="legend" required>
          Նվազագույն աշխատավարձը,որի դեպքում կցանկանայք աշխատել մեզ մոտ
        </FormLabel>
        <TextField
          name="minimumSalary"
          inputRef={register({ required: true, pattern: /^[0-9]*$/ })}
          error={errors.minimumSalary ? true : false}
          helperText={
            errors.minimumSalary &&
            (errors.minimumSalary.type === "required"
              ? "Պարտադիր լրացման դաշտ"
              : "Պետք է լինի միյայն թիվ")
          }
        />
      </div>
      <div>
        <FormLabel component="legend">
          Կցանկանա՞ք կիսվել ձեր փորձով և դասավանդել ուսանողներին
        </FormLabel>
        <TextField name="teachStudents" inputRef={register} />
      </div>
      <div>
        <FormLabel component="legend" required>
          Կրթություն (հաստատության անվանումը,մասնագիտությունը)
        </FormLabel>
        <TextField
          name="education"
          inputRef={register({ required: true })}
          error={errors.education ? true : false}
          helperText={errors.education ? "Պարտադիր լրացման դաշտ" : ""}
        />
      </div>
      <div>
        <FormLabel component="legend" required>
          Աշխատանքային փորձ (ընկերության անվանումը, զբաղեցրած պաշտոնը)
        </FormLabel>
        <TextField
          name="workExperience"
          inputRef={register({ required: true })}
          error={errors.workExperience ? true : false}
          helperText={errors.workExperience ? "Պարտադիր լրացման դաշտ" : ""}
        />
      </div>
      <div>
        <FormLabel component="legend">
          Եթե ունեք սեփական պրոեկտներ, կցեք դրանք այստեղ
        </FormLabel>
        <TextField name="ownProjects" inputRef={register} />
      </div>
    </>
  );
};

export default WorkingDataForm;
