import React from "react";

const LevelՕfKnowledge = () => {
  return (
    <tr className="first-tr">
      <td>
      </td>
      <td className="programm-languages-level">
        <p>
          <span>չգիտեմ</span>
          <span>բավարար</span>
          <span>լավ</span>
          <span>գերազանց</span>
        </p>
      </td>
    </tr>
  );
};

export default LevelՕfKnowledge;
