import React, { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";

import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

import StudentTable from "../../components/studentTable"

import "./pages.css"




const CssTextField = withStyles({
    root: {
        marginBottom: "25px",
        "& input": {
            color: "#000",
        },
    },
})(TextField);

const LoginStudent = ({ setAccessToken, setError, error }) => {
    const { register, handleSubmit, errors } = useForm({
        mode: "onBlur",
        defaultValues: {
            password: ""
        }
    });
    const handleOnSubmit = (data) => {
        axios.post(`${process.env.REACT_APP_BACKEND_PORT}/student/login`, data)
            .then(({ data }) => {
                const { accessToken } = data
                setAccessToken(accessToken)
                localStorage.setItem("student", accessToken)
            })
            .catch(() => {
                setError(true)
            })
    }

    return (
        <form
            style={{ textAlign: "center" }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(handleOnSubmit)}
        >
            <div>
                <CssTextField
                    label="password"
                    name="password"
                    type="password"
                    inputRef={register({ required: true })}
                    required
                    focused
                    error={errors.password || error ? true : false}
                    helperText={errors.password ? "Պարտադին լրացման դաշտ" : ""}
                    variant="outlined"
                />
            </div>
            <Button
                variant="outlined"
                color="primary"
                type="submit"
                style={{
                    minWidth: '223px', minHeight: '56px'
                }}>Մուտք</Button>

        </form >
    );
};


const StudentPage = () => {
    const [student, setStudent] = useState({ fullName: "", scrapbooks: [] })
    const [error, setError] = useState(false)
    const [accessToken, setAccessToken] = useState(() => localStorage.getItem("student"))

    document.body.style.backgroundColor = "#D8EDFF";

    useEffect(() => {
        setError(false)
        if (!accessToken) {
            return
        }
        axios.get(`${process.env.REACT_APP_BACKEND_PORT}/student`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(({ data }) => {
                setStudent(data);
            })
            .catch(() => { })
    }, [accessToken]);


    return (
        <div >
            <h1 className="studentPageTitle">SourceCode ծրագրավորման կազմակերպության ուսանողի</h1>
            <h2 className="studentPageText">Հաճախումների մատյան</h2>
            {
                accessToken ? (
                    <>
                        <h3 className="studentPageText">{student.fullName}</h3>
                        < StudentTable data={student.scrapbooks} />
                         <p style={{ color: "#0b3f64", fontWeight: "bold", textAlign: "center" }}>
                            <span style={{ backgroundColor: "#00F3FF", padding: "0px 8px", border: "1px solid", margin: "5px" }}>
                            </span > 
</p>
                    </>
                ) : <LoginStudent  {...{ setAccessToken, setError, error }} />
            }
            {
                error &&
                <ul className="studentPageErrorContainer" >
                    Մուտքը չհաջողվեց։ Հնարավոր պատճառները՝
                    <li className="studentPageErrorText"> Դուք չեք հանդիսանում մեր ուսանողը</li>
                    <li className="studentPageErrorText"> Դուք այլևս չեք սովորում մեզ մոտ</li>
                    <li className="studentPageErrorText"> Դուք սխալ եք մուտքագրել գաղտնաբառը</li>
                </ul>
            }
        </div >

    )
}


export default StudentPage



