import * as React from "react";
import {
  Create,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  ImageInput,
  TopToolbar,
  ListButton,
} from "react-admin";

import { PreviewImage } from "../common/components";
import { formatImage } from "../common/formatImage";

const ReviewCreateToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="save and show" redirect="show" submitOnEnter={true} />
    <SaveButton
      label="save and add"
      redirect={false}
      submitOnEnter={false}
      variant="text"
    />
  </Toolbar>
);

const ReviewCreateActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

const ReviewCreate = (props) => (
  <Create {...props} actions={<ReviewCreateActions />}>
    <SimpleForm toolbar={<ReviewCreateToolbar />}>
      <TextInput source="fullName" validate={required()} />
      <TextInput
        source="review"
        validate={required()}
        fullWidth
        multiline
        resettable
      />
      <ImageInput
        format={formatImage}
        validate={required()}
        source="picture"
        placeholder="Drop a picture to upload, or click to select it. (maxSize 200 KB)"
        accept="image/png, image/jpg, image/jpeg"
        maxSize={200000}
      >
        <PreviewImage source="url" />
      </ImageInput>
    </SimpleForm>
  </Create>
);

export default ReviewCreate;
