import React, { useState } from "react";
import { BackgroundImage } from 'react-image-and-background-image-fade'

import ModalCoursesOnlineInfo from "../modal/ModalCoursesOnlineInfo"
import CourseStartCountDown from '../courseStartCountDown';

import './onlineLesson.css';

const OnlineLesson = ({ courses, isLoading }) => {
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true)
    }
    return (
        <>
            <BackgroundImage
                element='div'
                className="online"
                src={`/images/discuss.jpg`}
                lazyLoad
                transitionTime="0.5s"
            >
                <CourseStartCountDown {...{ courses, isLoading }} />
                <div className="border-for-online-lesson"></div>
                <div className="online-course">
                    <h4>Սկսեք առցանց ուսուցում</h4>
                    <h2>Ընդլայնեք ձեր հմտությունները լավագույն առցանց դասընթացների միջոցով</h2>
                    <button onClick={handleClickOpen} className="btns btn4">Մանրամասներ</button>
                </div>
            </BackgroundImage>
            <ModalCoursesOnlineInfo {...{ open, setOpen }} />
        </>
    )
}

export default OnlineLesson;