import * as React from "react";
import { Layout, AppBar, UserMenu } from "react-admin";

const MyUserMenu = (props) => <UserMenu {...props}></UserMenu>;

const MyAppBar = (props) => <AppBar {...props} userMenu={<MyUserMenu />} />;

const MyLayout = (props) => <Layout {...props} appBar={MyAppBar} />;

export default MyLayout;
