import * as React from "react";
import { Children, cloneElement, memo } from "react";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  EditButton,
  ShowButton,
  List,
  FunctionField,
  SimpleList,
  TextField,
} from "react-admin";

import { PreviewAvatar } from "../common/components";

const userListActionToolbarStyles = makeStyles({
  toolbar: {
    alignItems: "center",
    display: "flex",
    marginTop: -1,
    marginBottom: -1,
  },
});
const UserListActionToolbar = ({ children, ...props }) => {
  const classes = userListActionToolbarStyles();
  return (
    <div className={classes.toolbar}>
      {Children.map(children, (button) => cloneElement(button, props))}
    </div>
  );
};

const UserBulkActionButtons = memo((props) => (
  <BulkDeleteWithConfirmButton {...props} />
));

const UserList = (props) => (
  <List {...props} bulkActionButtons={<UserBulkActionButtons />}>
    {useMediaQuery((theme) => theme.breakpoints.down("sm")) ? (
      <SimpleList
        primaryText={(record) => record.fullName}
        secondaryText={(record) => record.email}
        tertiaryText={(record) => `Role: ${record.role}`}
        leftAvatar={(record) => PreviewAvatar(record)}
      />
    ) : (
        <Datagrid rowClick="show" optimized>
          <FunctionField
            label="Avatar"
            render={(record) => PreviewAvatar(record)}
          />
          <TextField source="fullName" sortable={false} />
          <TextField source="phone" sortable={false} />
          <TextField source="email" sortable={false} />
          <TextField source="role" sortable={false} />
          <UserListActionToolbar>
            <EditButton />
            <ShowButton />
          </UserListActionToolbar>
        </Datagrid>
      )}
  </List>
);

export default UserList;
