import React from "react";
import { Link } from "react-scroll";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import LazyLoad from "react-lazyload";

import SocialMedia from "../socialMedia";

import "./footer.css";


const instagramInfo = {
  img: "../images/instagram.svg",
  href: "https://github.com/fisshy/react-scroll",
  discription:
    "Հետևեք մեզ Instagram-ում և տեղյակ եղեք IT ոլորտի նորություններին և աշխատանքային հայտարարություններին!"
}

const usefulLinksName = [
  { linkName: "Մեր Մասին", scrollToElementName: "about_us" },
  { linkName: "Կարծիքներ", scrollToElementName: "feedbacks" },
  { linkName: "Կապ մեզ հետ", scrollToElementName: "contact_us" },
  { linkName: "Դասընթացներ", scrollToElementName: "courses" },
  { linkName: "Բլոգ", scrollToElementName: "blog" },
];

const Footer = ({ settings, isLoading }) => {
  const { pathname } = useLocation();

  let instagram_link = ""

  settings.forEach(element => {
    if (element.settingName === "instagram_link") {
      instagram_link = element.value
    }
  })

  const Links = usefulLinksName.map((item, i) => {
    return (
      <li key={i}>
        <RouterLink
          className="navbar-brand"
          to={{
            pathname: "/",
            state: { scrollToElementName: item.scrollToElementName },
          }}
        >
          {item.linkName}
        </RouterLink>
      </li>
    );
  });

  let d = new Date();
  const currentYear = d.getFullYear();
  return (
    <LazyLoad offset={100}>
      <footer>
        <div className="container-for-footer">
          <div className="footer-parent">
            <div className="socials">
              <h3 className="useful">Հետևեք մեզ</h3>
              <Carousel
                autoPlay
                interval={4000}
                changeOnFirstRender={false}
                indicators={false}
                timeout={1000}
                animation={"slide"}
              >
                {Array.from(new Array(2)).map((_, index) => {
                  return (
                    <div key={index} className="links-slider">
                      <img src={instagramInfo.img} alt="instagram" />
                      <p>
                        <a href={instagram_link} target="_blank" rel="noopener noreferrer">SourceCode</a>
                        {instagramInfo.discription}
                      </p>

                    </div>
                  );
                })}
              </Carousel>
            </div>
            <div className="social">
              <iframe
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fsourcecode.am&tabs=timeline&width=350&height=250&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                width="350"
                height="250"
                style={{ border: "none", overflow: "hidden" }}
                scrolling="no"
                frameBorder="0"
                allowtransparency="true"
                allow="encrypted-media"
                title="Fb"
              />
            </div>
            <div className="useful-links">
              <h3 className="useful usf">Օգտակար հղումներ</h3>
              <ul className="vac">
                <li>
                  <RouterLink className="navbar-brand" to="/vacancies" target="_blank">
                    Թափուր աշխատատեղ
                  </RouterLink>
                </li>
                {pathname === "/" ? (
                  <>
                    <li>
                      <Link
                        to="about_us"
                        spy={true}
                        smooth={true}
                        duration={0}
                        offset={-80}
                      >
                        Մեր մասին
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="feedbacks"
                        spy={true}
                        smooth={true}
                        duration={0}
                        offset={-120}
                      >
                        Կարծիքներ
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="contact_us"
                        spy={true}
                        smooth={true}
                        duration={0}
                        offset={-80}
                      >
                        Կապ մեզ հետ
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="courses"
                        spy={true}
                        smooth={true}
                        duration={0}
                        offset={-80}
                      >
                        Դասընթացներ
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="blog"
                        spy={true}
                        smooth={true}
                        duration={0}
                        offset={-80}
                      >
                        Բլոգ
                      </Link>
                    </li>
                  </>
                ) : (
                  Links
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright">
          <SocialMedia {...{ settings, isLoading }} />
          <p className="rights">
            <img src={`${window.location.origin}/images/copyright-symbol.svg`} className="copy-right" alt="copyright" />{currentYear} <a href="https://sourcecode.am/" target="_blank" rel="noopener noreferrer" className="link-development-page">SourceCode LTD</a>, All Rights Reserved
          </p>
        </div>
      </footer>
    </LazyLoad>
  );
};


export default Footer;
