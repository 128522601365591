export default () => {
    document.addEventListener("contextmenu", (e) => {
        e.preventDefault();
    });
    document.onkeydown = (e) => {
        e = (e || window.e);
        if (e.altKey || e.ctrlKey || e.metaKey) {
            return false
        }
        if (e.code === "F12") {
            return false;
        }
    }
}