import React, { useState } from 'react'
import PropTypes from 'prop-types'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'



const AddStudentDialog = props => {
    const [student, setStudent] = useState("")
    const { addStudentHandler } = props
    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setStudent("")
    }

    const handleAdd = () => {
        addStudentHandler(student)
        handleClose()
    }

    const handleChange = ({ target: { value } }) => {
        setStudent(value)
    }

    return (
        <div>
            <Tooltip title="Add">
                <IconButton aria-label="add" onClick={handleClickOpen}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            Student
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Add Student</DialogTitle>
                <DialogContent>
                    <DialogContentText>Add new student</DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Full name"
                        type="text"
                        fullWidth
                        value={student}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAdd} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

AddStudentDialog.propTypes = {
    addUserHandler: PropTypes.func.isRequired,
}

export default AddStudentDialog
