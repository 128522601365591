import * as React from "react";
import {
  TopToolbar,
  Edit,
  CloneButton,
  ShowButton,
  SimpleForm,
  TextInput,
  required,
  ListButton,
} from "react-admin";
import FAQTitle from "./FAQTitle";

const FAQEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <CloneButton className="button-clone" basePath={basePath} record={data} />
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);

const FAQEdit = ({ ...props }) => (
  <Edit title={<FAQTitle />} actions={<FAQEditActions />} {...props}>
    <SimpleForm>
      <TextInput
        multiline
        fullWidth
        resettable
        source="question"
        validate={required()}
      />
      <TextInput
        multiline
        fullWidth
        resettable
        source="answer"
        validate={required()}
      />
    </SimpleForm>
  </Edit>
);

export default FAQEdit;
