import React from "react";
import { Link, useHistory } from "react-router-dom";

import "./errorPage.css";
import errorImg from "./error.png"

const ErrorPage = () => {
  const history = useHistory()
  return (
    
    <div className="error-message">
      <div className="error-background">
        <img src={errorImg} alt="error" />
        <div>
          <h3>Awww...<span>Don't Cry</span></h3>
          <h2>
            <span>Sorry,</span> something went wrong. We are currently trying to fix
                    the problem.
                </h2>
          <h2>
            Please try later.
                </h2>
          <Link to="/">
            <button>SourceCode</button>
          </Link>
          <button onClick={() => history.go(0)}><i className="fas fa-sync-alt"></i>Refresh</button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
