import QuestionAnswerRoundedIcon from "@material-ui/icons/QuestionAnswerRounded";
import FAQCreate from "./FAQCreate";
import FAQEdit from "./FAQEdit";
import FAQList from "./FAQList";
import FAQShow from "./FAQShow";

const FAQ = {
  list: FAQList,
  create: FAQCreate,
  edit: FAQEdit,
  show: FAQShow,
  icon: QuestionAnswerRoundedIcon,
};

export default FAQ;
