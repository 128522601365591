import * as React from "react";
import {
  Create,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  TopToolbar,
  ListButton,
} from "react-admin";

const FAQCreateToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="save and edit" redirect="edit" submitOnEnter={true} />
    <SaveButton
      label="save and show"
      redirect="show"
      submitOnEnter={false}
      variant="text"
    />
  </Toolbar>
);
const FAQCreateActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

const FAQCreate = (props) => {
  return (
    <Create {...props} actions={<FAQCreateActions />}>
      <SimpleForm toolbar={<FAQCreateToolbar />}>
        <TextInput
          autoFocus
          source="question"
          fullWidth
          validate={required()}
          multiline
          resettable
        />
        <TextInput
          source="answer"
          validate={required()}
          fullWidth
          multiline
          resettable
        />
      </SimpleForm>
    </Create>
  );
};

export default FAQCreate;
