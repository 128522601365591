import React, { useState } from "react";
import { Link } from "react-router-dom";
import Countd from "../countdown";
import Carousel from "react-material-ui-carousel";
import { withStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

import ModalRegisterCourses from "../modal";

import './courseStartCountDown.css'



const CssSkeleton = withStyles({
    root: {
        backgroundColor: "lightgrey"
    },
})(Skeleton);

const CourseStartCountDown = ({ courses, isLoading }) => {
    const [open, setOpen] = useState(false);

    const [courseInfo, setCourseInfo] = useState();

    const handleClickOpen = ({
        title,
        availableGroupPrice,
        availableIndividualPrice,
        onlineGroupPrice,
        onlineIndividualPrice
    }) => {
        setCourseInfo({
            title,
            availableGroupPrice,
            availableIndividualPrice,
            onlineGroupPrice,
            onlineIndividualPrice
        });
        setOpen(true);
    };


    return (
        <>
            <div className="countdown-for-new-lessons">
                <Carousel
                    interval={5000}
                    navButtonsAlwaysVisible
                    indicators={false}
                    timeout={1000}
                    animation={"slide"}
                  
                >
                    {(isLoading ? Array.from(new Array(1)) : courses).map((item, index) => (
                        <div key={index} className="hurry-up">
                            <h1>Շտապեք գրացվել առաջիկայում սկսվող դասընթացներին</h1>
                            <div>
                                <div>
                                    <h2>
                                        {item ? <> {item.title} <span> ծրագրավորում </span> </> :
                                            <CssSkeleton variant="text" width={280} height={35} className="title-loading"/>}
                                    </h2>
                                    <div className="condition">
                                        <span>Պայմաններ: </span>
                                      <p>
                                            {item ? `տևողությունը՝ ${item?.duration} ամիս շաբաթական 3օր՝ 2 ժամով` :
                                            <CssSkeleton variant="text" height={30} className="countdown-text-loading" />}
                                        </p>
                                    </div>
                                    <div className="plan">
                                        <span>Ծրագրում:</span>
                                        <div className="lili">
                                            {item ?
                                                item?.languages.map((item, i) => <span key={i}>{item.language}</span>) :
                                                <CssSkeleton variant="text"  height={30} className="countdown-text-loading"/>}
                                        </div>
                                    </div>
                                </div>
                                <div className="starting-less">
                                    <h5 className="quickly1">Դասընթացի մեկնարկին մնաց՝</h5>
                                    <Countd data={item?.counter} isLoading={isLoading} />
                                </div>
                            </div>
                            <div className="latest">
                                {isLoading ? null : <div className="reg-btn">
                                    <button
                                        className="register btns btn4"
                                        onClick={() => handleClickOpen(item)}
                                    >
                                        Գրանցվել
                    </button>
                                    <Link to={`/courses/${item.href}`} className="ssilka btns btn4">
                                        Մանրամասներ
                    </Link>
                                </div>}

                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
            <ModalRegisterCourses
                {...{ open, setOpen, courseInfo, Id: "formModalButton_2" }} />
        </>
    )
}

export default CourseStartCountDown;