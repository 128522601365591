import React, { useState } from "react";
import { useForm } from "react-hook-form";
import validator from "validator";
import Skeleton from "@material-ui/lab/Skeleton";
import { withStyles, makeStyles, TextField } from "@material-ui/core";
import { BackgroundImage } from 'react-image-and-background-image-fade'

import AlertDialog from "../modal/AlertDialog";

import "./getInTouch.css";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: " #00FFD5",
    },

    "& label": {
      color: "#FFFFFF",
    },
    "& input": {
      color: "#FFFFFF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: " #00FFD5",
    },
  },
})(TextField);

const CssSkeleton = withStyles({
  root: {
    backgroundColor: "lightgrey",
  },
})(Skeleton);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(2.9),
  },
}));

const altVersion = (
  <p>
    Շնորհակալություն մեզ վստահելու համար։ Ցավոք Ձեր հայտը չի ուղարկվել
    տեխնիկական խնդիրների պատճառով։ Մեզ հետ կարող եք կապ հաստատել նաև
    <a
      href="https://www.facebook.com/sourcecode.am/"
      target="_blank"
      rel="noopener noreferrer"
    >
      {" այստեղ։"}
    </a>
  </p>
);

const GetInTouch = ({ settings, isLoading }) => {
  const classes = useStyles();

  const { register, handleSubmit, errors, reset } = useForm({
    mode: "onBlur",
  });

  const [messageRespons, setMessageRespons] = useState("");
  const [openAlertDialog, setOpenAlertDialog] = useState(false);

  const handleOnSubmit = (data) => {
    fetch(`${process.env.REACT_APP_BACKEND_PORT}/sendLetter`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error();
        }
        setMessageRespons(
          "Շնորհակալություն մեզ վստահելու համար։ Ձեր հայտը հաջողությամբ ուղարկվել է։"
        );
      })
      .catch(() => {
        setMessageRespons(altVersion);
      })
      .finally(() => {
        setOpenAlertDialog(true);
        reset();
      });
  };

  return (
    <BackgroundImage
      element='section'
      className="get-in-touch"
      src={"/images/lili.jpg"}
      transitionTime="0.5s"
      lazyLoad
    >
      <div className="leftTouch">
        <h1>
          <span>Եկեք</span> կապ հաստատենք
        </h1>
        <p>Աջակցության կամ ցանկացած հարցի դեպքում կապվեք մեզ հետ:</p>
        <ul>
          <li>
            <img src="images/phone-call.svg" alt="phone" loading="lazy" />
            <div className="text">
              {(isLoading
                ? Array.from(new Array(2))
                : settings.filter((item) => item.settingName === "phone")
              ).map((item, i) =>
                item ? (
                  <span key={item._id}>
                    <a href={`tel:${item.value}`}>{item.value}</a>
                  </span>
                ) : (
                  <CssSkeleton
                    key={i}
                    variant="text"
                    width={"100%"}
                    height={20}
                  />
                )
              )}
            </div>
          </li>
          <li>
            <img src="images/mail.svg" alt="mail" />
            <div className="text">
              {(isLoading
                ? Array.from(new Array(2))
                : settings.filter((item) => item.settingName === "email")
              ).map((item, i) =>
                item ? (
                  <span key={item._id}>
                    <a href={`mailto:${item.value}`}>{item.value}</a>
                  </span>
                ) : (
                  <CssSkeleton
                    key={i}
                    variant="text"
                    width={"100%"}
                    height={20}
                  />
                )
              )}
            </div>
          </li>
        </ul>
      </div>
      <div className="rightTouch">
        <h2>
          Խնդրում ենք լրացնել ձևաթուղթը և
          <span> մենք կապ կհաստատենք Ձեզ հետ </span> շատ արագ
        </h2>
        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          className={classes.root}
          noValidate
          autoComplete="off"
        >
          <CssTextField
            inputRef={register({ required: true })}
            name="name"
            required
            error={errors.name ? true : false}
            helperText={errors.name ? "Պարտադին լրացման դաշտ" : ""}
            className={classes.margin}
            label="Անուն"
          />
          <CssTextField
            name="lastname"
            required
            inputRef={register({ required: true })}
            error={errors.lastname ? true : false}
            helperText={errors.lastname ? "Պարտադին լրացման դաշտ" : ""}
            className={classes.margin}
            label="Ազգանուն"
          />
          <CssTextField
            name="email"
            required
            inputRef={register({
              required: true,
              validate: (value) => validator.isEmail(value) || "invalidEmail",
            })}
            error={errors.email ? true : false}
            helperText={
              errors.email &&
              (errors.email.type === "required"
                ? "Պարտադին լրացման դաշտ"
                : "Սխալ  Էլ․ հասցե")
            }
            className={classes.margin}
            label="Էլ. հասցե"
          />
          <CssTextField
            placeholder="+374XXXXXXXX"
            name="phone"
            inputRef={register({
              required: true,
              validate: (value) =>
                validator.isMobilePhone(value, "am-AM") || "invalidPhone",
            })}
            required
            error={errors.phone ? true : false}
            helperText={
              errors.phone &&
              (errors.phone.type === "required"
                ? "Պարտադին լրացման դաշտ"
                : "Սխալ հեռախոսահամար")
            }
            className={classes.margin}
            label="Հեռախոսահամար"
          />
          <CssTextField
            name="message"
            required
            inputRef={register({
              required: true,
              validate: (value) => !(value.length > 1000),
            })}
            error={errors.message ? true : false}
            helperText={
              errors.message &&
              (errors.message.type === "required"
                ? "Պարտադին լրացման դաշտ"
                : "Առավելագույն սիմվոլների քանակը 1000")
            }
            inputProps={{ className: "last" }}
            className={classes.margin}
            label="Թողնել հաղորդագրություն"
            multiline
            rows={2}
          />
          <div className="btn">
            <button className="btn4 btns">Ուղարկել</button>
          </div>
        </form>
      </div>
      <AlertDialog
        {...{
          openAlertDialog,
          setOpenAlertDialog,
          messageRespons,
        }}
      />
    </BackgroundImage>
  );
};

export default GetInTouch;
