import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import ColoredLinearProgress from "../../coloredLinearProgress"

import "./courseHeader.css";

const CourseHeader = ({ progressing }) => {
  const [isLoadingView, setIsLoadingView] = useState(true)

  useEffect(() => {
    let timerId
    setIsLoadingView(true)
    if (progressing === 100) {
      timerId = setTimeout(() => setIsLoadingView(false), 1500)
    }
    return () => {
      clearTimeout(timerId)
    }
  }, [progressing])
  return (
    <>
      {isLoadingView ? <ColoredLinearProgress variant="determinate" value={progressing} /> : null}
      <div className="header-parent">
        <div>
          <Link to="/">
            <img src={`${process.env.REACT_APP_BACKEND_PORT}/logo.webp`} alt="Source-Code-logo" />
          </Link>
        </div>
        <div>
          <Link to={{ pathname: "/", state: { scrollToElementName: "courses" } }}>
            <button className="btn4 btns">Դասընթացներ</button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default CourseHeader;
