import * as React from "react";
import {
  TopToolbar,
  ArrayInput,
  Edit,
  ListButton,
  CloneButton,
  BooleanInput,
  ShowButton,
  ImageInput,
  NumberInput,
  SimpleFormIterator,
  SimpleForm,
  TextInput,
  required,
  minValue,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";
import RichTextInput from 'ra-input-rich-text';

import PostTitle from "./CourseTitle";
import { PreviewImage } from "../common/components";
import { formatImage } from "../common/formatImage";

const CourseEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <CloneButton className="button-clone" basePath={basePath} record={data} />
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);

const CourseEdit = (props) => (
  <Edit title={<PostTitle />} actions={<CourseEditActions />} {...props}>
    <SimpleForm>
      <TextInput source="title" validate={required()} />
      <TextInput source="href" validate={required()} />
      <RichTextInput
        source="condition"
        fullWidth
        validate={required()} />
      <NumberInput
        source="availableIndividualPrice"
        label="Available individual price"
        min={0}
        validate={[required(), minValue(0)]}
      />
      <NumberInput
        source="availableGroupPrice"
        label="Available group price"
        min={0}
        validate={[required(), minValue(0)]}
      />
      <NumberInput
        source="onlineIndividualPrice"
        label="Online individual price"
        min={0}
        validate={[required(), minValue(0)]}
      />
      <NumberInput
        source="onlineGroupPrice"
        label="Online group price"
        min={0}
        validate={[required(), minValue(0)]}
      />
       <NumberInput
        source="studentsQuantity"
        label="Students quantity"
        min={0}
        validate={[required(), minValue(0)]}
      />
      <NumberInput source="duration" validate={[required(), minValue(0)]} />
      <NumberInput
        source="startDateCourse"
        label="Start date course"
        min={0}
        validate={[required(), minValue(0)]}
      />
      <BooleanInput label="Available" source="available" />
      <BooleanInput label="Display on slides" source="displayՕnSlides" />
      <ReferenceArrayInput
        label="tutors"
        source="tutors"
        reference="admin/users"
        validate={required()}
      >
        <SelectArrayInput optionText="fullName" optionValue="id" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Information about tutor"
        source="tutorsInfo"
        reference="admin/teams"
        validate={required()}
      >
        <SelectArrayInput optionText="fullName" optionValue="id" />
      </ReferenceArrayInput>
      <ArrayInput source="languages" validate={required()}>
        <SimpleFormIterator>
          <TextInput source="language" label="language" validate={required()} />
          <ImageInput
            format={formatImage}
            validate={required()}
            placeholder="Drop a picture to upload, or click to select it. (maxSize 20 KB)"
            source="logo"
            label="logo"
            accept="image/png, image/jpg, image/jpeg"
            maxSize={20000}
          >
            <PreviewImage source="url" />
          </ImageInput>
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="notes" label="notes" validate={required()}>
        <SimpleFormIterator>
          <TextInput
            source="note"
            label="note"
            multiline
            validate={required()}
            fullWidth
            resettable
          />
        </SimpleFormIterator>
      </ArrayInput>
      <ImageInput
        format={formatImage}
        validate={required()}
        source="picture"
        placeholder="Drop a picture to upload, or click to select it. (maxSize 200 KB)"
        accept="image/png, image/jpg, image/jpeg"
        maxSize={200000}
      >
        <PreviewImage source="url" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

export default CourseEdit;
