import React from 'react';


const SocialLinks = ({ socialLinks }) => {
  return (
    <>
        <a href={socialLinks.slack_link} target="_blank" rel="noopener noreferrer" >
          <img src="../images/slack.svg" alt="slack" />
        </a>
        <a href={socialLinks.viber_link} target="_blank" rel="noopener noreferrer">
          <img src="../images/viber.svg" alt="viber" />
        </a>
        <a href={socialLinks.linkedin_link} target="_blank" rel="noopener noreferrer" >
          <img src="../images/linkedin.svg" alt="linkedin" />
        </a>
        <a href={socialLinks.whatsapp_link} target="_blank" rel="noopener noreferrer" >
          <img src="../images/whatsapp.svg" alt="whatsapp" />
        </a>
        <a href={socialLinks.telegram_link} target="_blank" rel="noopener noreferrer" >
          <img src="../images/telegram.svg" alt="telegram" />
        </a>
    </>
  )
}

export default SocialLinks