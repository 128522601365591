import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";

import {
  HomePage,
  BlogPage,
  CoursePage,
  Page404
} from "../routeCollector/page"
import JobApplicationForm from "../components/jobApplicationForm";
import AdminPage from "../components/Admin/AdminPage";
import StudentPage from "./page/StudentPage"

function ScrollToTop() {
  const { pathname } = useLocation();
  console.warn = () => { }
  console.error = () => { }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });

  }, [pathname]);
  return null;
}


const RouteCollector = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/vacancies" component={JobApplicationForm} />
        <Route exact path="/blogs/:title" component={BlogPage} />
        <Route exact path="/courses/:title" component={CoursePage} />
        <Route path={["/admin", "/login"]} component={AdminPage} />
        <Route exact path="/student" component={StudentPage} />
        <Route exact path="/.well-known/pki-validation/C8ED2D9AF9C755B82465A22CC20A564B.txt" />
        <Route path="*" component={Page404} />
      </Switch>
    </Router>
  );
};


export default RouteCollector;

