import React from "react";
import { useWatch } from "react-hook-form";
import {
  FormLabel,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@material-ui/core";
import { Controller } from "react-hook-form";

import StyledRadio from "./StyledRadio";

const LevelՕfKnowledgeRadioGroup = ({ fildInfo, RadioGroupName, control }) => {
  const values = useWatch({
    control,
    name: RadioGroupName,
  });
  return fildInfo.map((e) => {
    return (
      <Controller
        key={e}
        as={
          <tr>
        
            <td>
            <FormControl component="fieldset" className="fieldests">
            <RadioGroup
              row
              name={RadioGroupName}
              value={e === "Node.js" ? values["Nodejs"] : values[e]}
            >
                  <div>
                  <FormLabel component="legend">{e}</FormLabel>
                  </div>
                     <div>
                     <FormControlLabel value="չգիտեմ" control={<StyledRadio />} />
                    <FormControlLabel value="բավարար" control={<StyledRadio />} />
                    <FormControlLabel value="լավ" control={<StyledRadio />} />
                    <FormControlLabel
                      value="գերազանց"
                      control={<StyledRadio />}
                    />
                     </div>
            </RadioGroup>
          </FormControl>  
          </td>
          </tr>
        }
        name={`${RadioGroupName}.${e === "Node.js" ? "Nodejs" : e}`}
        control={control}
      />
    );
  });
};

export default LevelՕfKnowledgeRadioGroup;
