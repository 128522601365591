import { RateReviewRounded } from "@material-ui/icons";
import ReviewCreate from "./ReviewCreate";
import ReviewEdit from "./ReviewEdit";
import ReviewList from "./ReviewList";
import ReviewShow from "./ReviewShow";

const review = {
  list: ReviewList,
  create: ReviewCreate,
  edit: ReviewEdit,
  show: ReviewShow,
  icon: RateReviewRounded,
};

export default review;
