import React from 'react'
import LazyLoad from 'react-lazyload'

import './map.css'


const Map = () => {
    return (
        <LazyLoad offset={100} className="map" >
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3048.125537510848!2d44.52416331570132!3d40.18401957768286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abd65d8fbf223%3A0xbc90cf32e98bb0a8!2sSource%20Code!5e0!3m2!1sen!2s!4v1611647803057!5m2!1sen!2s"
                width="100%" height="450" frameBorder="0" style={{ border: 0 }} allowFullScreen="" aria-hidden="false" tabIndex="0" title="map" />
        </LazyLoad>
    )
}


export default React.memo(Map)