import React from "react";
import { Button, makeStyles, FormLabel } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const useStyles = makeStyles((theme) => ({
  inputFile: {
    display: "none",
  },
}));

const CvInput = ({ register, watch, errors }) => {
  const cvFile = watch("cvFile");
  const classes = useStyles();
  return (
    <div className="cv">
      <FormLabel component="legend">Ավելացրեք ձեր CV</FormLabel>
      <input
        name="cvFile"
        ref={register({
          validate: {
            checkSize: (value) =>
            (value && value[0] instanceof File
              ? value[0].size < 1000000
              : true),
            checkType: (value) => {
              const regex = new RegExp("(.*?)(pdf|docx|doc)$");
              return (value && value[0] instanceof File
                ? regex.test(value[0].name)
                : true)
            }
          },
        })}
        accept="application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        className={classes.inputFile}
        id="icon-button-cv_file"
        type="file"
      />
      <div className="cv-button-for-job">
        <label htmlFor="icon-button-cv_file">
          <Button
            component="span"
            type="button"
            variant="contained"
            color="default"
            startIcon={<CloudUploadIcon />}
          >
            Ներբեռնել
        </Button>
        </label>
        {cvFile && cvFile[0] instanceof File ? <p>{cvFile[0].name}</p> : null}
      </div>
      {errors.cvFile ? (
        <span className="error-for-files">
          {errors.cvFile.type === "checkSize"
            ? "Ֆայլի չափը չպետք է գերազանցի 1 մեգաբայթը"
            : "Ֆայլը պետք է լինի հետևյալ ձևաչափով (.pdf, .docx,.doc)"}
        </span>
      ) : null}
    </div>
  );
};

export default CvInput;
