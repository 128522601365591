import React from "react";
import { Controller } from "react-hook-form";
import {
  FormLabel,
  FormControlLabel,
  Checkbox,
  TextField,
  FormGroup,
  FormControl,
} from "@material-ui/core";

const KnowledgeOfLanguages = ({ register, control }) => {
  return (
    <div className="languages">
      <FormLabel component="legend" className="level-of-knowledge">
        Լեզուների իմացություն
        </FormLabel>
      <FormControl component="fieldset">
        <FormGroup row >
          {["Հայերեն", "Ռուսերեն", "Անգլերեն", "Ֆրանսերեն"].map((language, i) => {
            return (
              <Controller
                key={language}
                control={control}
                name={`knowledgeOfLanguages[${i}]`}
                render={(props) => (
                  <div className="languages-level">
                    <FormControlLabel
                      label={language}
                      labelPlacement="start"
                      control={<Checkbox
                        color="primary"
                        onChange={(e) => props.onChange(e.target.checked ? language : "")}
                        checked={!!props.value}
                      />}
                    />
                  </div>
                )}
              />
            );
          })}
        </FormGroup >
      </FormControl>
      <TextField
        placeholder="Այլ"
        name="otherLanguages"
        inputRef={register}
      />
    </div>

  );
};

export default KnowledgeOfLanguages;
