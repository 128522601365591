import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Element, scroller } from "react-scroll";
import LazyLoad from 'react-lazyload';
import axios from "axios"
import MetaTags from 'react-meta-tags';

import eventsCancel from "../../pageEvents"

import "./pages.css"

import Menu from "../../components/menu";
import Footer from "../../components/footer";
import Map from "../../components/map";
import GetInTouch from "../../components/getInTouch";
import Counter from "../../components/counter";
import FAQ from "../../components/FAQ";
import AboutUs from "../../components/aboutUs";
import Banner from "../../components/banner";
import Team from "../../components/team";
import Blog from "../../components/blog";
import Advantages from "../../components/advantages";
import Online from "../../components/onlineLesson";
import Revies from "../../components/revies";
import Course from "../../components/course";
import ErrorPage from "../../components/errorPage";
import Arrow from "../../components/arrow"
import MessnegerChat from "../../components/messengerChat"




const HomePage = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [progressing, setProgressing] = useState(0)
  const [data, setData] = useState({
    courses: [],
    FAQs: [],
    blogs: [],
    revies: [],
    teams: [],
    settings: []
  });
  const { state } = useLocation();
let  bannerPicture = ""
for(let i = 0; i < data.settings.length;i++){
   if(data.settings[i].bannerPicture){
    bannerPicture =data.settings[i].bannerPicture
    break
}
}
  const handlerOpenNav = (e) => {
    const { id, dataset } = e.target
    if (id === "social-media" || id === "nav-list" || dataset.navItem === "nav-item") return
    if (!isMenuOpen) return;
    setIsMenuOpen(false);
  };

  const scrollToElementName = state?.scrollToElementName || "home";

  useEffect(() => {
    let ignore = false;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    eventsCancel()
    async function fetchData() {
      setIsError(false);
      setIsLoading(true);
      setProgressing(0)
      try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_PORT, {
          cancelToken: source.token,
          timeout: 10000,
          onDownloadProgress: (progressEvent) => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgressing(percentCompleted)
          }
        })

        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        if (!ignore) setData(response.data);
      } catch (error) {
        if (error.message === "Request aborted") return;
        if (error.message === "AbortError") return;
        setIsError(true);
      }
      setIsLoading(false);
      scroller.scrollTo(scrollToElementName, {
        offset: -80,
      });
    }
    fetchData();
    return () => {
      source.cancel('AbortError');
      ignore = true;
    };
  }, [props, scrollToElementName]);

  if (isError) return <ErrorPage />;

  const courses = data.courses.filter((item) => item.displayՕnSlides === true)
  return (
    <div onClick={handlerOpenNav} className="global-style">
    <MetaTags>
  <meta property="og:url"    content={window.location.href} />
  <meta property="og:title"         content="Ծրագրավորման դասընթացներ | SourceCode" />
  <meta property="og:description"   content="Sourcecode-ը հանդիսանում է ծրագրավորման ծառայություն մատուցող կազմակերպություն շուրջ 5 տարի։ Սրան զուգահեռ, այն նաև ունի ուսկենտրոնի բաժին, որտեղ կազմակերպում են ծրագրավորման դասընթացներ՝ հենց կազմակերպությունում աշխատող մասնագետների կողմից։"/>
  <meta property="og:image"     content= {`https://sourcecode.training/api/${bannerPicture}`}/>
    </MetaTags>
      <Element name="home">
        <Menu   {...{ settings: data.settings, isMenuOpen, setIsMenuOpen, progressing }} />
      </Element>

      <Element name="home">
        <Banner {...{ settings: data.settings }} />
      </Element>
      <Advantages />

      <Element name="courses" >
        <Course courses={data.courses} isLoading={isLoading} />
        <Online  {...{ courses }} isLoading={isLoading} />
      </Element>

      <Element name="FAQ">
        <FAQ FAQs={data.FAQs} isLoading={isLoading} />
      </Element>

      <Element name="blog">
        <Blog blogs={data.blogs} isLoading={isLoading} />
      </Element>

      <Element name="about_us" >
        <AboutUs />
      </Element>

      <Counter />

      <Element name="feedbacks" >
        <Revies revies={data.revies} isLoading={isLoading} />
      </Element>

      <Element name="our_team" >
        <Team teams={data.teams} isLoading={isLoading} />
      </Element>

      <Element name="contact_us"  >
        <GetInTouch settings={data.settings} isLoading={isLoading} />
      </Element>

        <Map />
        
      <Footer settings={data.settings} isLoading={isLoading} />
      <Arrow />
      <MessnegerChat />
    </div >
  );
};

export default HomePage;
