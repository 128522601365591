import React from "react";
import validator from "validator";
import { TextField, FormLabel } from "@material-ui/core";

import PictureInput from "./PictureInpute";
import RadiosForm from "./RadiosForm";

const PersonalInformationForm = ({ register, errors, control, watch }) => {
  return (
    <>
      <div>
        <FormLabel component="legend" required>
          Անուն Ազգանուն Հայրանուն
        </FormLabel>
        <TextField
          name="fullName"
          inputRef={register({ required: true })}
          error={errors.fullName ? true : false}
          helperText={errors.fullName ? "Պարտադիր լրացման դաշտ" : ""}
        />
      </div>
      <div>
        <PictureInput {...{ register, watch, errors }} />
      </div>
      <div>
        <FormLabel component="legend" required>
          Ծննդյան տարեթիվ
        </FormLabel>
        <TextField
          type="date"
          name="age"
          inputRef={register({ required: true })}
          error={errors.age ? true : false}
          helperText={errors.age ? "Պարտադիր լրացման դաշտ" : ""}
        />
      </div>
      <div className="gender">
        <FormLabel component="legend" required>
          Սեռ
        </FormLabel>
        <RadiosForm
          {...{
            fildInfo: ["Արական", "Իգական"],
            control,
            errors,
            RadioGroupName: "gender",
          }}
        />
      </div>
      <div className="marital-status">
        <FormLabel component="legend" required>
          Ամուսնական կարգավիճակ
        </FormLabel>
        <RadiosForm
          {...{
            fildInfo: ["Ամուսնացած", "Չամուսնացած"],
            errors,
            control,
            RadioGroupName: "maritalStatus",
          }}
        />
      </div>
      <div>
        <FormLabel component="legend" required>
          Բջջ․ հեռախոս
        </FormLabel>
        <TextField
          placeholder="+374XXXXXXXX"
          name="phone"
          inputRef={register({
            required: true,
            validate: (value) =>
              validator.isMobilePhone(value, "am-AM") || "invalidPhone",
          })}
          required
          error={errors.phone ? true : false}
          helperText={
            errors.phone &&
            (errors.phone.type === "required"
              ? "Պարտադիր լրացման դաշտ"
              : "Սխալ հեռախոսահամար")
          }
        />
      </div>
      <div>
        <FormLabel component="legend" required>
          Էլ․ հասցե
        </FormLabel>
        <TextField
          name="email"
          inputRef={register({
            required: true,
            validate: (value) => validator.isEmail(value) || "invalidEmail",
          })}
          error={errors.email ? true : false}
          helperText={
            errors.email &&
            (errors.email.type === "required"
              ? "Պարտադիր լրացման դաշտ"
              : "Սխալ  Էլ․ հասցե")
          }
        />
      </div>
      <div>
        <FormLabel component="legend" required>
          Բնակության հասցե
        </FormLabel>
        <TextField
          name="residenceAddress"
          inputRef={register({ required: true })}
          error={errors.residenceAddress ? true : false}
          helperText={errors.residenceAddress ? "Պարտադիր լրացման դաշտ" : ""}
        />
      </div>
    </>
  );
};

export default PersonalInformationForm;
