import React from 'react'
import PropTypes from 'prop-types'



const ExtraLessonEditableCell = ({
    data,
    updateMyData,
}) => {
    return (
        <>
            <p style={{ color: "red" }}>մեկ հնարավորությամբ լրացված դասեր</p>
            {
                data.map((item, i) => {
                    const { month, extraԼesson } = item
                    return (
                        <div key={month}>
                            <lable>{` month ${month} -`}</lable>
                            <input type="date" name={month}
                                value={extraԼesson}
                                onChange={(e) => updateMyData(i, "extraԼesson", e.target.value)}
                                style={{
                                    padding: 0,
                                    marginTop: 5,
                                    border: 0,
                                    background: 'transparent',
                                    marginLeft: "15px"
                                }} />
                        </div>
                    )
                })
            }
        </>


    )
}


ExtraLessonEditableCell.propTypes = {
    data: PropTypes.array.isRequired,
    updateMyData: PropTypes.func.isRequired
}




export default ExtraLessonEditableCell
