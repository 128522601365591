import React, { useState, useEffect, useRef,useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import LazyLoad from 'react-lazyload';
import { Element, Link, scroller } from "react-scroll";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import axios from "axios"
import { FacebookShareButton, FacebookIcon } from "react-share";

import ErrorPage from "../../errorPage"

import "./aboutBlog.css";

const GetDate = (data) => {
  const date = new Date(data)
  return `${date.getDate()} / ${date.getMonth() + 1} / ${date.getFullYear()}`
}

const LoadingCourseCard = () => {
  return (
    <Box>
      <Skeleton width="85%" height={35} />
      <Skeleton width="50%" height={30} />
      <Skeleton width="20%" height={30} />
    </Box>
  )
}

const AboutBlog = ({ blogs, numberOfBlogs, setData, demoBlog, isLoading, setProgressing }) => {

  const [isLoadingViewMore, setIsLoadingViewMore] = useState(false);
  const [isError, setIsError] = useState(false);
  const controllerRef = useRef();
  const history = useHistory();

 useLayoutEffect(() => {
    if (demoBlog.title) {
      document.querySelector("meta[property='og:url']").content = `${window.location.href}`
      document.querySelector("meta[property='og:title']").content = `${demoBlog.title}`
      document.querySelector("meta[property='og:description']").content = "Նյութին ավելի մանրամասն ծանոթանալու համար, անցեք հղմամբ"
      document.querySelector("meta[property='og:image']").content = `https://sourcecode.training${process.env.REACT_APP_BACKEND_PORT}/${demoBlog.picture}`
    }
  }, [demoBlog])


  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    controllerRef.current = source.token
    return () => source.cancel('AbortError');
  }, []);

  const handlingViewMore = async () => {
    setIsError(false);
    setIsLoadingViewMore(true);
    setProgressing(0)
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_PORT}/blogs?skip=${blogs.length}`, {
        cancelToken: controllerRef.current,
        timeout: 10000,
        onDownloadProgress: (progressEvent) => {
          let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgressing(percentCompleted)
        }
      })
      if (response.status < 200 || response.status >= 300) {
        throw new Error();
      }
      setData((prevState) => {
        return { ...prevState, blogs: [...prevState.blogs, ...response.data.blogs] };
      });
    } catch (error) {
      if (error.message === "Request aborted") return;
      if (error.message === "AbortError") return;
      setIsError(true);
    }
    setIsLoadingViewMore(false);
  };

  const handlingMoreClick = (blog) => {
    history.push(
      `/blogs/${blog.href}`
    )
    setTimeout(() => scroller.scrollTo("moreAboutBlog", {
      duration: 200,
      smooth: true,
      offset: -120,
    }), 500)
    setData((prevState) => {
      return { ...prevState, demoBlog: blog };
    })
  }

  const checkViewMore = (numberOfBlogs !== blogs.length)

  if (isError) return <ErrorPage />;

  return (
    <section className="about-blog-parent">
      <div className="more-about-blog-background">
        <div className="blog-text">
          <h3>SourceCode-ի հետ ծանոթացիր</h3>
          <h2>Վերջին նորություններին և հոդվածներին</h2>
        </div>
        <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
          <polygon points="-30,300 355.167,210.5 1432.5,290 1920,198.5 1920,300"></polygon>
        </svg>
      </div>

      <div className="more-about-blog">
        <Element name="moreAboutBlog">
          <div className="more-about-blog-top">
            <div className="blog-images-and-text">
              <div>
                {isLoading || !demoBlog.picture ? <Skeleton variant="rect" width={300} height={307} />
                  : <img
                    src={`${process.env.REACT_APP_BACKEND_PORT}/${demoBlog.picture}`}
                    alt={demoBlog.title}
                    className="more-img"
                    loading="lazy"
                  />}
              </div>
              <div className="blog-title">
                <h2>{isLoading ? <Skeleton variant="rect" width={300} height={30} /> : demoBlog.title}</h2>
                <span>{isLoading ? <Skeleton variant="rect" width={100} height={20} /> : GetDate(demoBlog.publication_date)}
                </span>
              <FacebookShareButton
                    url={window.location.href}
                    quote={`${demoBlog.title}`}
                    hashtag={"#ծրագրավորմաննյութեր"}
                    className="ShareButtonBlog"
                  >   <FacebookIcon size={32} />
                    <p className="ShareButtonText">կիսվել</p>
                  </FacebookShareButton>
              </div>
            </div>
            <div className="blog-info">
              {isLoading ?
                <Box >
                  <Skeleton width="100%" height={"25%"} />
                  <Skeleton width="100%" height={"25%"} />
                  <Skeleton width="100%" height={"25%"} />
                </Box>
                : <div dangerouslySetInnerHTML={{ __html: demoBlog.description }} />}
            </div>
          </div>
        </Element>
        <div className="more-about-blog-bottom">
          <div className="more-about-blog-full">
            <div className="more-about-full">
              {(isLoading ? Array.from(new Array(3)) : blogs).map((blog, i) => {
                return (
                  <LazyLoad height={200} offset={100} key={i}>
                    <div >
                      <div className="blog-img-parent">
                        {blog ? <img
                          src={`${process.env.REACT_APP_BACKEND_PORT}/${blog.picture}`}
                          alt={blog.title}
                        /> : <Skeleton variant="rect" width="100%" height="90%" className="blog-img-loading" />}
                      </div>
                      {blog ?
                        <div className="more-info-blog">
                          <h3>{blog.title}</h3>
                          <span>{GetDate(blog.publication_date)}</span>
                          <Link onClick={() => handlingMoreClick(blog)}>Մանրամասն</Link></div> : <LoadingCourseCard />}
                    </div>
                  </LazyLoad>
                );
              })
              }
              {isLoadingViewMore
                ? Array.from(new Array(3)).map((_, i) => (
                  <div key={i}>
                    <div className="blog-img-parent">
                      <Skeleton variant="rect" width="100%" height={"100%"} />
                    </div>
                    <LoadingCourseCard key={i} />
                  </div>))
                : null}
            </div>
          </div>
          <div className="view-more">
            <LazyLoad height={200} offset={100} >
              {!isLoading ? < button onClick={handlingViewMore} disabled={!checkViewMore}>
                Տեսնել Ավելին
                <img
                  src={`${window.location.origin}/images/${checkViewMore ? "eye" : "close-eye"}.svg`}
                  alt="eye"
                  className="eye"
                />
              </button> : null
              }
            </LazyLoad>
          </div>
        </div>
      </div>
    </section >
  );
};

export default AboutBlog;
