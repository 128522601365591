import React, { useState, useEffect } from "react";
import Carousel from "react-material-ui-carousel";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { Link } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { BackgroundImage } from 'react-image-and-background-image-fade'

import ModalRegisterCourses from "../modal/ModalRegisterCourses";

import "./course.css";

const BoxVariantsTitle = {
  visible: { opacity: 1, scale: 1, transition: { duration: 0.8 } },
  hidden: { opacity: 0, scale: 0.2 }
};

const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "mobile";
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};

const Course = ({ courses, isLoading }) => {
  const [open, setOpen] = useState(false);
  const [courseInfo, setCourseInfo] = useState();
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);


  const handleClickOpen = ({
    title,
    availableGroupPrice,
    availableIndividualPrice,
    onlineGroupPrice,
    onlineIndividualPrice
  }) => {
    setCourseInfo({
      title,
      availableGroupPrice,
      availableIndividualPrice,
      onlineGroupPrice,
      onlineIndividualPrice
    });
    setOpen(true);
  };
  const deviceType = getDeviceType()
 
  const info = (isLoading
    ? Array.from(new Array((matches ? 8 : 1)))
    : courses).map((item, i) => {
      return (
        !item ? <div key={i} className="container-card-course">
          <Skeleton variant="rect" width={340} height={415} />
        </div>
          : <BackgroundImage
            element='div'
            key={item.id}
            data-device-type={deviceType}
            className="container-card-course"
            src={`${process.env.REACT_APP_BACKEND_PORT}/${item.picture}`}
            transitionTime="1s"
            lazyLoad
          >
            <div className="overlay"  data-device-type={deviceType} >
              <div className="items"></div>
              <div className="items price"  data-device-type={deviceType}>
                <h3>{item.title}</h3>
                <ul className="features-list">
                  {item.languages.map((item, i) => (
                    <li key={i}>
                      <img src="images/check.svg" className="check-course" alt="check" />
                      {item.language}
                    </li>
                  ))}
                </ul>
                <div className="ul-border"></div>
              </div>
              <div className="items cart"  data-device-type={deviceType}>
                <div className="buttons">
                  {item.available ? <>
                    <Link to={`/courses/${item.href}`}>
                      <button className="btn-primary btns btn4">Մանրամասներ</button>
                    </Link>
                    <button
                      className="btn-two btns btn4"
                      onClick={() => handleClickOpen(item)}
                    >
                      Գրանցվել
                    </button>
                  </> :
                    <button
                      className="btns btn4"
                      style={{ cursor: "default" }}
                    >
                      Հասանելի չէ
                    </button>}
                </div>
              </div>
            </div>
          </BackgroundImage>
      );
    });

  const elements = matches ? (
    info
  ) : (
    <Carousel
      autoPlay={false}
      navButtonsAlwaysVisible
      changeOnFirstRender={false}
      indicators={false}
      timeout={600}
      animation={"slide"}
    >
      {info}
    </Carousel>
  );

  return (
    <div className="information">
      <div className="info-title">
        <motion.h4
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={BoxVariantsTitle}>
          Ընտրիր քեզ հետաքրքրող <span>դասընթացը</span>
        </motion.h4>
      </div>
      <div className="cards-course">
        {elements}
      </div>
      <ModalRegisterCourses
        {...{ open, setOpen, courseInfo, Id: "formModalButton_1" }}
      />
    </div>
  );
};

export default Course;

